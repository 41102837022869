import React from 'react';

function DeniedLabel(props) {
    const centered = {
        display:"flex",
        justifyContent: "center",
        alignItems: "center",
        height: "200px",
    }
    return (
        <div>
            <h1 style={centered}>У Вас недостаточно прав для доступа к этому разделу</h1>
        </div>
    );
}

export default DeniedLabel;
