import React from 'react';
import {InputText} from "primereact/inputtext";

function NameFilter(props) {
    const {params, setParams} = props
    return (<div className="p-input-icon-left p-d-block" >
        <i className="pi pi-search" />
        <InputText value={params.name} className=""
                   onChange ={(e) => {setParams({...params, name:e.target.value})}}
                   placeholder="Поиск" autoFocus/>
    </div>)
}

export default NameFilter;