import {getSnapshot, types} from "mobx-state-tree";
import ApiService from "../../../shared/api/ApiService";
import {EditableMonitoring} from "./Monitoring";
import {PharmaType} from "../../PhamaType/model/PharmaType";
import {PatientState} from "../../Patient/model/PatientState";
import {PrescriptionsStore} from "../../Prescription/model/PrescriptionsStore";
import {Patient} from "../../Patient/model/Patient";

export const MonitoringStore = types.model({
    prescriptionId: types.maybeNull(types.number),
    item: types.maybeNull(EditableMonitoring),
    pharmaTypes: types.maybeNull(types.array(PharmaType)),
    isLoading: false,
    patient: types.maybeNull(Patient),
    patientStore: types.maybeNull(PatientState),
    prescriptionStore: types.maybeNull(PrescriptionsStore)
}).actions(self => ({
    init(prescriptionStore){
        if (self.prescriptionId !== prescriptionStore.selected.id){
            self.prescriptionStore = prescriptionStore
            self.prescriptionId = prescriptionStore.selected.id
            self.isLoading = true;
            self.patient = Patient.create(getSnapshot(prescriptionStore.selected.patient))
            ApiService.getMonitoringByPrescription(self.prescriptionId).then(data=>{
                self.fetchSuccess(data)
            }).catch(e=>{
                self.fetchError(e)
            })
        }
    },
    fetchSuccess(data){
        self.item = data
        self.error = ""
        self.item.clearIsDirty()
        self.isLoading = false
    },
    fetchError(error) {
        self.item = EditableMonitoring.create({prescriptionId:self.prescriptionId})
        self.isLoading = false
    },
    fetchPatientSuccess(data){
        self.patient = data
        self.patient.clearIsDirty()
        self.prescriptionStore.updatePatientIfNeed(self.patient)
        if (self.item.isDirty){
            ApiService.saveMonitoring(self.item).then(data =>{
                self.fetchSuccess(data)
            }).catch(e => {
                self.fetchError(e)
            })
        } else {
            self.isLoading = false;
        }
    },
    fetchPatientError(error){
        self.error = error
        self.isLoading = false
    },
    save(){
        self.isLoading = true
        if (self.patient.isDirty){
            ApiService.savePatient(self.patient).then(data => {
                self.fetchPatientSuccess(data)
            }).catch(error => {
                self.fetchPatientError(error)
            })
        } else {
            ApiService.saveMonitoring(self.item).then(data =>{
                self.fetchSuccess(data)
            }).catch(e => {
                self.fetchError(e)
            })
        }
    }
})).views(self=>({
    get isDirty(){
        return self.item?.isDirty || self.patient.isDirty
    }
}))

let _store
export const useMonitoringStore = ()=> {
    if (!_store) {
        _store = MonitoringStore.create()
    }
    return _store;
}