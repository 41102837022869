import React from 'react';
import {
    ClearToolButtonOutlined,
    SelectionToolButtonOutlined
} from "./Styles";
import PropTypes from "prop-types";

function ExtListItemEditor(props) {
    const {value, onSelect, onClear, readOnly, placeholder} = props
    return (
        <div className={`p-inputtext p-component ${props.className}`} style={props.style}>
            <div className="content-grid" >
                <input
                    className="item-text"
                    type="text"
                    placeholder={placeholder}
                    value={value}
                    style={{border:"none"}}
                    readOnly
                    tabIndex={-1}
                />
                {!readOnly && <span style={{display:"inline-block",verticalAlign:"middle"}}>
                    <SelectionToolButtonOutlined
                        type="button"
                        onClick={onSelect}
                    />
                </span>}
                {!readOnly && <span style={{display:"inline-block",verticalAlign:"middle"}}>
                    <ClearToolButtonOutlined
                        type="button"
                        onClick={onClear}
                    />
                </span>}
            </div>
        </div>
    );
}

ExtListItemEditor.propTypes = {
    value: PropTypes.any,
    onSelect: PropTypes.func,
    onClear: PropTypes.func,
    readOnly: PropTypes.bool,
    placeholder: PropTypes.string
}

export default ExtListItemEditor;
