import React from 'react';
import {Switch} from "react-router-dom";
import ToolBar from "./ToolBar";
import {appState} from "../../../app/model/AppState";
import {observer} from "mobx-react-lite";
import Patients from "./Patients";
import {propisi} from "../../../app/config/global";
import PatientCard from "./PatientCard";
import RestrictedRoute from "../../../entyties/User/lib/RestrictedRoute";

function DoctorUI(props) {
    return (
        <div>
            <ToolBar />
            <Switch>
                <RestrictedRoute path="/doctor" exact level={propisi.DOCTOR_ROLE} >
                    <Patients store={appState.doctorUI}></Patients>
                </RestrictedRoute>
                <RestrictedRoute path="/doctor/patient/:id" exact level={propisi.DOCTOR_ROLE} >
                    <PatientCard store={appState.doctorUI}></PatientCard>
                </RestrictedRoute>
            </Switch>
        </div>
    );
}

export default observer(DoctorUI);
