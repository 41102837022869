import React, {useEffect, useState} from 'react';
import {appState} from "../../../app/model/AppState";
import {Prescription} from "../../../entyties/Prescription/model/Prescription";
import {format, parseISO} from "date-fns";
import {observer} from "mobx-react-lite";
import SplitPane from "react-split-pane";
import {Panel} from "primereact/panel";
import {CenteredItemStyled, FullCenteredItemStyled} from "../../../shared/ui/CenteredItems.styled";
import {InputText} from "primereact/inputtext";
import DateEditor from "../../../shared/ui/DateEditor/DateEditor";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";

import ToolBar from "./ToolBar";
import PrescriptionUI from "./PrescriptionUI";
import {RadioButton} from "primereact/radiobutton";
import {InputMask} from "primereact/inputmask";


function PaharmacyUI(props) {

    //const [showPrescription, setShowPrescription] = useState(false);
    const [params, setParams] = useState({
        finished: false,
        first: 0,
        rows:10,
        page:0
    });

    const patientsHeader = (
        <div className="p-panel-header" style={{justifyContent:"center"}}>
            <div style={{textAlign:"center"}}>Назначения</div>
        </div>
    )

    useEffect(() => {
        appState.pharmacistUI.fetchPrescriptions({...params})
    }, [params]);

    const onSelectPrescription = (e)=>{
        appState.pharmacistUI.setPrescription(e.value)
    }

    const onPage = (event) =>{
        let _params = { ...params, ...event };
        setParams(_params);
    }

    const dateBody = (rowData) =>{
        return format(parseISO(rowData.date) ,"dd.MM.yyyy")
    }

    const onSavePrescription = (data) =>{
        updatePrescription(data)
        appState.pharmacistUI.setPrescription(Prescription.create(data))
    }

    const onFinishedPrescription = (prescription) =>{
        if (params["finished"]!==undefined){
            appState.pharmacistUI.setPrescriptions(
                appState.pharmacistUI.prescriptions.filter(p => (p.id !== prescription.id))
            )
        }
    }

    const updatePrescription = (data) =>{
        appState.pharmacistUI.setPrescriptions(
            appState.pharmacistUI.prescriptions.map(p =>(p.id === data.id ? Prescription.create(data) : p))
        )
    }

    return (
        <div>
            <ToolBar />

            <SplitPane
                split="vertical" primary="first"
                defaultSize={400}
                minSize={350}
                style={{position:"relative", overflow:"visible"}}
            >
                <div>
                    <Panel className="ml-1" headerTemplate={patientsHeader} >

                        <div className="mt-2" style={{display:"grid", gridTemplateColumns:"100px 1fr", gridRowGap:"0.5rem"}}>
                            <div>Показывать</div>
                            <div>
                                <RadioButton inputId="new" name="new" value="Новые"
                                             onChange={(e) => setParams({...params, finished: false})}
                                             checked={params['finished']!==undefined && !params.finished}
                                />
                                <label className="ml-1" htmlFor="new">К выдаче</label>
                                <RadioButton  className="ml-1" inputId="finished" name="new" value="Все"
                                    onChange={(e) => {
                                        delete params.finished
                                        setParams({...params})
                                    }}
                                     checked={params["finished"]===undefined}
                                />
                                <label className="ml-1" htmlFor="finished">Все</label>
                            </div>
                            <CenteredItemStyled>Код</CenteredItemStyled>
                            <InputText value={params.prescription_id || ""}
                                       onChange ={(e) => {setParams({...params, prescription_id:e.target.value})}}
                                       autoFocus
                            />
                            <CenteredItemStyled>Фамилия</CenteredItemStyled>
                            <InputText value={params.surname || ""}
                                       onChange ={(e) => {setParams({...params, surname:e.target.value})}}
                            />
                            <CenteredItemStyled>Имя</CenteredItemStyled>
                            <InputText value={params.name || ""}
                                       onChange ={(e) => {setParams({...params, name:e.target.value})}}
                            />
                            <CenteredItemStyled>Отчество</CenteredItemStyled>
                            <InputText value={params.patronymic || ""}
                                       onChange ={(e) => {setParams({...params, patronymic:e.target.value})}}
                            />
                            <CenteredItemStyled>СНИЛС</CenteredItemStyled>
                            <InputMask mask="999-999-999 99" value={params.snils || ""}
                                       onChange ={(e) => {setParams({...params, snils:e.target.value})}}
                            />
                            <CenteredItemStyled>Дата</CenteredItemStyled>
                            <div style={{display:"grid", gridTemplateColumns:"1fr 2rem 1fr"}}>
                                <DateEditor
                                    overlayPos="top"
                                    value={params.begin_date ? parseISO(params.begin_date) : ""} style={{width:"100%"}}
                                    onChange ={(e) => {
                                        console.log(e)
                                        setParams({...params, begin_date:e.toISOString()})}
                                    }
                                    onClear={()=>{setParams({...params, begin_date:""})}}
                                />
                                <FullCenteredItemStyled> по </FullCenteredItemStyled>
                                <DateEditor
                                    overlayPos="top"
                                    value={params.end_date ? parseISO(params.end_date) : ""} style={{width:"100%"}}
                                    onChange ={(e) => {
                                        setParams({...params, end_date:e.toISOString()})}
                                    }
                                    onClear={()=>{setParams({...params, end_date:""})}}
                                />
                            </div>
                        </div>

                        <DataTable
                            value={appState.pharmacistUI.prescriptions}
                            className="mt-1"
                            selectionMode="single"
                            emptyMessage="Записи не найдены"
                            selection={appState.pharmacistUI.prescription}
                            onSelectionChange={onSelectPrescription}
                            size="small"
                            paginator first={params.first} rows={params.rows}
                            totalRecords={appState.pharmacistUI.totalPrescriptions}
                            onPage={onPage}
                        >
                            <Column field="patient.person.fullName" header="Ф.И.О"/>
                            <Column body={dateBody} header="Дата"/>
                        </DataTable>
                        {/*<div >{appState.pharmacistUI.prescriptions.length}</div>*/}

                    </Panel>
                </div>
                <div>
                    <PrescriptionUI
                        prescription={appState.pharmacistUI.prescription}
                        setPrescription={appState.pharmacistUI.setPrescription}
                        onSavePrescription={onSavePrescription}
                        onFinished={onFinishedPrescription}
                    />
                </div>
            </SplitPane>

        </div>
    );
}

export default observer(PaharmacyUI);