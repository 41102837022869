import React, {useRef} from 'react';
import DayPickerInput from "react-day-picker/DayPickerInput";
import MomentLocaleUtils, {
    formatDate,
    parseDate,
} from 'react-day-picker/moment';
import 'moment/locale/ru';
import PropTypes from "prop-types";
import './daypicker_style.css';
import styled from "styled-components";

const HoveredI = styled.i`
    &&:hover{
      color:#2196f3;
    }
`

const weekCount = (year, month_number) =>{
    const firstOfMonth = new Date(year, month_number - 1, 1);
    let day = firstOfMonth.getDay() || 6;
    day = day === 1 ? 0 : day;
    if (day) { day-- }
    let diff = 7 - day;
    const lastOfMonth = new Date(year, month_number, 0);
    const lastDate = lastOfMonth.getDate();
    if (lastOfMonth.getDay() === 1) {
        diff--;
    }
    return Math.ceil((lastDate - diff) / 7) + 1;
}

function DateEditor(props){

    const inputRef = useRef(null)

    const OverlayComponent = (overlayPos) =>{
        return ({ children, ...props }) =>{
            const rect = {
                top:inputRef.current?.input.offsetTop,
                height:inputRef.current?.input.offsetHeight,
                left: inputRef.current?.input.offsetLeft,
                width: inputRef.current?.input.offsetWidth
            }

            let map = new Map();
            map.set(4, 180);
            map.set(5, 205);
            map.set(6, 230);

            const wc = weekCount(props.month.getFullYear(), props.month.getMonth()+1)
            const top = overlayPos === "top" ? rect.top - map.get(wc) : rect.top+rect.height+5

            const overlayStyle = {
                zIndex:"100",
                position:"absolute",
                top:`${top}px`,
                left:`${rect.left+(rect.width/2-90)}px`,
                backgroundColor:"#fff",
                border: "solid 1px #CED4DA",
                boxShadow: "0 5px 5px rgba(0, 0, 0, 0.15)"
            }

            return (
                <div
                    onBlur={props.onBlur}
                    onFocus={props.onFocus}
                    style={overlayStyle}
                >
                    { children }
                </div>
            );
        }
    }

    return (
        <div>
            <span className="p-input-icon-right">
            {/*<IconField>*/}
            <HoveredI
                className={`pi  ${(inputRef.current?.state.value && props.onClear) ? 'pi-times':'pi-calendar'}`}
                style={{"&:hover":{color:"red"}}}
                onClick={()=>{
                    if (inputRef.current?.state.value && props.onClear)
                        props.onClear()
                    else inputRef.current.handleInputClick()
                }}
            />
            <DayPickerInput
                style = {props.style}
                onDayChange={day => {if(day) props.onChange(day)}}
                inputProps={{
                    className: `p-inputtext p-component ${props.className}` ,
                    style:props.style
                }}
                format="DD.MM.YYYY"
                value={props.value}
                showOverlay={props.showOverlay}
                formatDate={formatDate}
                parseDate={(e)=>{return parseDate(e,"D.M.YYYY") }}
                placeholder=""
                readOnly={props.readOnly}
                // showOverlay={true}
                ref = {inputRef}
                dayPickerProps={{
                    locale: 'ru',
                    showOutsideDays:true,
                    localeUtils: MomentLocaleUtils,
                }}
                overlayComponent={OverlayComponent(props.overlayPos)}/>
            {/*</IconField>*/}
            </span>
        </div>
    );
}

DateEditor.prototype = {
    style: PropTypes.object,
    overlayPos: PropTypes.string,
    value: PropTypes.object,
    onChange: PropTypes.func,
    onClear: PropTypes.func
}

export default DateEditor;
