import React from 'react';

function ToolButton(props) {
    return (
        <button {...props}>
            <span style={{fontSize:props.fontSize}} className={`pi ${props.icon}`}/>
        </button>
    );
}

export default ToolButton;