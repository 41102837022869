import styled from "styled-components";
import {Button} from "primereact/button";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, {registerLocale} from 'react-datepicker';
import {Fieldset} from "primereact/fieldset";
import {Panel} from "primereact/panel";
import {Password} from "primereact/password";
import {Card} from "primereact/card";

import ru from 'date-fns/locale/ru';
import ReactQuill from "react-quill";
import ToolButton from "./ToolButton";
import ExtListItemEditor from "./ExtListItemEditor";
import {Chip} from "primereact/chip";

registerLocale('ru-RU', ru)

export const SCalendar = styled(DatePicker).attrs(props =>({
    className:"p-inputtext p-component",
    locale:"ru-RU",
    dateFormat:"dd.MM.yyyy",
}))``

export const Row = styled.div.attrs(props => ({
    className:"p-grid"
}))`
    margin-top: -1px;
    margin-left: 5px;
    margin-right: 5px;
`
export const NestedRow = styled.div.attrs(props => ({
    className:"p-grid"
}))`
    padding: 0;
`
export const Cell = styled.div`
    border: 1px solid #ced4da;
    margin-left: -1px;
`

export const Label = styled.div`
    display: flex;
    align-items: center;
    font-weight: bold;
`

export const LabelCell = styled.div.attrs(props =>({
    className: "p-col-fixed" || props.className
}))`
  border: 1px solid #ced4da;
  margin-left: -1px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

export const TextCell = styled.div.attrs(props =>({
    className: "p-col" || props.className
}))`
  border: 1px solid #ced4da;
  display: flex;
  justify-content: normal;
  margin-left: -1px;
  flex-direction: row;
`

export const CellWitGrid = styled.div.attrs(props => ({
    className:"p-col"
}))`
    padding-top: 8px;
    padding-left: 8px;
    padding-bottom: 0;
    margin-left: -1px;
    border: 1px solid #ced4da;
    //display: flex;
`

export const CellWithFlexGrid = styled(CellWitGrid)`
    display: flex;
`

export const NestedCell = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
`

export const ClearButton = styled(Button).attrs(props => ({
    // className: props.className || "p-button-danger p-button-outlined",
    className: props.className || "p-button-danger",
    icon: props.icon || "pi pi-trash",
    tabIndex: -1
}))`
  
  &&&:hover{
    background: transparent!important;
    color: #c02929!important;
    box-shadow: 0 0 0 0.2rem #edacac;
  }
  
  &&& {
    width: 1.8rem;
    height: 1.8rem;
  }
`

export const ClearButtonOutlined = styled(Button).attrs(props => ({
    // className: props.className || "p-button-danger p-button-outlined",
    className: props.className || "p-button-danger",
    icon: props.icon || "pi pi-trash",
    tabIndex: -1
}))`
  
  &&&:hover{
    background: #c02929!important;
    color: #fff!important;
    box-shadow: 0 0 0 0.2rem #edacac;
  }
  
  &&& {
    background: transparent!important;
    color: #c02929!important;
    width: 1.8rem;
    height: 1.8rem;
  }
`

export const EditButtonOutlined = styled(Button).attrs(props => ({
    // className: props.className || "p-button-danger p-button-outlined",
    className: props.className || "p-button-primary",
    icon: props.icon || "pi pi-pencil",
    tabIndex: -1
}))`
  
  &&&:hover{
    background: #2196F3!important;
    color: #fff!important;
    box-shadow: 0 0 0 0.2rem #a6d5fa;
  }
  
  &&& {
    background: transparent!important;
    color: #2196F3!important;
    width: 1.8rem;
    height: 1.8rem;
  }
`

export const SelectionButton = styled(Button).attrs(props => ({
    className: props.className || "p-button-primary",
    icon: props.icon || "pi pi-ellipsis-h",
    tabIndex: -1
}))`
  &&&:hover{
    background-color: transparent!important;
    color: #2196F3!important;
    box-shadow: 0 0 0 0.2rem #a6d5fa;
  } 
  &&& {
    width: 1.8rem;
    height: 1.8rem;
  }
`

const ToolButtonFontSize = "13px"
const ToolButtonWidth = 2

export const BorderedListItemEditor = styled(ExtListItemEditor)`
    .content-grid{
        display: grid;
        grid-template-columns: 1fr ${ToolButtonWidth+0.1}rem ${ToolButtonWidth+0.1}rem;
    }
    .item-text:focus{
        outline:none;
    }
   :hover{
      border: solid 1px #2196F3;
   }
`

export const SelectionToolButtonOutlined = styled(ToolButton).attrs(props => ({
    icon: "pi-ellipsis-h",
    fontSize: ToolButtonFontSize
}))`
    :hover{
        background-color: #2196F3!important;
        color: #fff!important;
        box-shadow: 0 0 0 0.2rem #a6d5fa;
    }
    :focus{
      background-color: #2196F3!important;
      color: #fff!important;
      box-shadow: 0 0 0 0.2rem #a6d5fa;
      outline: none;
    }
  
    border: none;
    background-color: transparent!important;
    color: #2196F3!important;
    width: ${ToolButtonWidth}rem;
    height: 1.4rem;
   
      
`
export const ClearToolButtonOutlined = styled(ToolButton).attrs(props => ({
    icon: "pi-trash",
    fontSize: ToolButtonFontSize
}))`
    :hover{
        background: #c02929!important;
        color: #fff!important;
        box-shadow: 0 0 0 0.2rem #edacac;
    }
    :focus{
      background: #c02929!important;
      color: #fff!important;
      box-shadow: 0 0 0 0.2rem #edacac;  
      outline: none;
    }
  
    border: none;
    background-color: transparent!important;
    color: #c02929!important;
    width: ${ToolButtonWidth}rem;
    height: 1.4rem;
      
`

export const SelectionButtonOutlined = styled(Button).attrs(props => ({
    className: props.className || "p-button-primary",
    icon: props.icon || "pi pi-ellipsis-h",
    tabIndex: -1
}))`
  &&&:hover{
    background-color: #2196F3!important;
    color: #fff!important;
    box-shadow: 0 0 0 0.2rem #a6d5fa;
  } 
  &&& {
    background-color: transparent!important;
    color: #2196F3!important;
    width: 1.8rem;
    height: 1.8rem;
  }
`

export const PrimaryButton = styled(Button).attrs(props => ({
        className: props.className || "p-button-primary",
    }))`
  &&&:hover{
    background-color: transparent!important;
    color: #2196F3!important;
    box-shadow: 0 0 0 0.2rem #a6d5fa;
  } 
`

export const PrimaryIconOnlyButton = styled(Button).attrs(props => ({
    className: props.className || "p-button-primary",
}))`
  &&&:hover{
    background-color: transparent!important;
    color: #2196F3!important;
    box-shadow: 0 0 0 0.2rem #a6d5fa;
  } 
  &&& {
    width: 1.8rem;
    height: 1.8rem;
  }
`

export const DangerIconOnlyButton = styled(Button).attrs(props => ({
    className: props.className || "p-button-outlined p-button-danger",
}))`
  &&&:hover{
    background: #c02929!important;
    color: #fff!important;
    box-shadow: 0 0 0 0.2rem #edacac;
  } 
  &&& {
    width: 1.8rem;
    height: 1.8rem;
  }
`

export const PrimaryButtonOutlined = styled(Button).attrs(props => ({
    className: props.className || "p-button-primary",
}))`
  &&&:hover{
    background-color: #2196F3!important;
    color: #fff!important;
    box-shadow: 0 0 0 0.2rem #a6d5fa;
  } 
  &&& {
    background-color: transparent!important;
    color: #2196F3!important;
    width: 1.8rem;
    height: 1.8rem;
  }
`

export const ReqItemPanel = styled(Panel)`
    .p-panel-content{
      border-left: none;
      border-right: none;
      border-bottom: none;
    }
`

export const SFieldset = styled(Fieldset)`
    text-align: center;
`
export const SPanel = styled(Panel)`
    
`
export const SPassword = styled(Password)`
    .p-inputtext{
      width:100%
    }
`

export const PasswordCard = styled(Card)`
    .p-card-body{
      height:100%; 
    }
    .p-card-content{
      height:100%;
      display:flex;
      flex-direction: column;
      justify-content: center;
    }
`

export const StyledChip = styled(Chip)`
  //background: #0d89ec;
  //color: white;
  margin-left: 2px;
  margin-bottom: 2px;
`

export const QuillEditor = styled(ReactQuill).attrs(props => ({
    modules:{toolbar: ["bold","italic","underline",{"script":"sub"},{"script":"super"}]}
}))`
    .ql-toolbar{
      border: none; 
      border-bottom: 1px solid #ced4da;
      height: 30px;
      padding: 3px;
    }
    .ql-container{
      border: none;
    }
    :hover{
      border: 1px solid #2196F3;
    }
    :focus-within{
      border-color: #2196F3;
      box-shadow: 0 0 0 0.2rem #a6d5fa;
    }
    border: 1px solid #ced4da;
    border-radius: 3px;
`
export const QuillSimpleEditor = styled(ReactQuill).attrs(props => ({
    modules:{toolbar: false}
}))`
    .ql-toolbar{
      border: none; 
      border-bottom: 1px solid #ced4da;
      height: 30px;
      padding: 3px;
    }
    .ql-container{
      border: none;
    }
    :hover{
      border: 1px solid #2196F3;
    }
    :focus-within{
      border-color: #2196F3;
      box-shadow: 0 0 0 0.2rem #a6d5fa;
    }
    border: 1px solid #ced4da;
    border-radius: 3px;
`

export const QuillViewer = styled(QuillEditor).attrs(props => ({
    readOnly: true,
    modules:{toolbar:false}
}))`
    
`
