import {useState, useLayoutEffect} from'react';

const queryes = [
    '(max-width: 766px)',
    '(min-width: 767px) and (max-width: 1199px)',
    '(min-width: 1200px)'
]
export const useMatchMedia = () => {

    const mediaQueryList = queryes.map(query => window.matchMedia(query));
    const getValues = () => {
        return mediaQueryList.map(query => query.matches)
    };
    const [values, setValues] = useState(getValues());

    useLayoutEffect(() => {
        const handler = () => {setValues(getValues())};
        mediaQueryList.forEach(query => query.addEventListener('change', handler));
        return () => mediaQueryList.forEach(query => query.removeEventListener('change', handler));
        // eslint-disable-next-line
    },[values])

    return ['isMobile','isTablet','isDesktop'].reduce((acc, key,index) => ({
        ...acc,
        [key]: values[index]
    }), {})
}