import React from 'react';
import image from './empty-folder.svg'
import PropTypes from "prop-types";

function EmptyItem(props) {
    const {height, label} = props
    return (
        <div style={{display:"table", height:height, width:"100%"}}>
            <div style={{display:"table-cell", textAlign:"center", verticalAlign:"middle"}}>
                <img alt="Empty" src={image} style={{opacity:"0.5"}} height={80} width={80} />
                <div style={{color:"rgba(0, 0, 0, 0.50)"}}>{label}</div>
            </div>
        </div>
    );
}

EmptyItem.propTypes = {
    height: PropTypes.string,
    label: PropTypes.string
}

export default EmptyItem;
