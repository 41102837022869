import {clone, types} from "mobx-state-tree";
import {Prescription} from "../../../entyties/Prescription/model/Prescription";
import ApiService from "../../../shared/api/ApiService";

export const PharmacistUIState = types.model({
    prescriptions: types.array(Prescription),
    prescription: types.maybe(Prescription),
    isLoading: false,
    totalPrescriptions: types.maybeNull(types.integer),
    error:""
}).actions(self => ({
    fetchPrescriptions(params){
        self.isLoading = true;
        ApiService.getPrescriptions(params).then(data =>{
            self.fetchPrescriptionsSuccess(data)
        }).catch(e =>{
            self.fetchPrescriptionsError(e)
        })
    },
    fetchPrescriptionsSuccess(data){
        self.isLoading = false
        self.totalPrescriptions = data.totalElements
        self.prescriptions=[]
        if (data.content){
            data.content.forEach(i=>{
                self.prescriptions.push(Prescription.create(i))
            })
        }
        if (self.prescriptions.length > 0){
            self.prescription = clone(self.prescriptions[0])
        } else self.prescription = undefined

    },
    fetchPrescriptionsError(error){
        self.isLoading = false
        self.error = error.message
    },
    setPrescription(prescription){
        if (prescription)
            self.prescription = clone(prescription)
        else
            self.prescription = undefined
    },
    setPrescriptions(prescriptions){
        self.prescriptions = prescriptions
    }
}))