import React, {useEffect, useState} from 'react'
import {Card} from "primereact/card";
import {Button} from "primereact/button";
import {getProperty} from "./utils";

function EditableCard(props) {

    const {columns, value, rowIndex, onSave, onCancel} = props;

    const [current, setCurrent] = useState({...value});
    useEffect(() => {
        setCurrent({...value});
    },[value])

    return (
        <Card className={"mt-2"}>
            <div>
                {columns.map((column, index) => {
                   if (column.editor)
                       return (<div key={index} className={"mt-2"} style={{display: "grid", gridTemplateColumns: "150px auto"}}>
                           <div  style={{fontWeight: "bold"}}>{`${column.header}:`}</div>
                           <div>{ column.editor.apply(void 0,[{
                           name: column.field,
                           value: current[column.field],
                           rowData: current,
                           editorCallback: (data) => {
                               setCurrent({...current, [column.field]: data})
                           }
                       }]) }</div></div>)
                   else {console.log(current); return (
                       <div key={index} className={"mt-2"} style={{display: "grid", gridTemplateColumns: "150px auto"}}>
                           <div style={{fontWeight: "bold"}}>{column.header ?`${column.header}:`:""}</div>
                           <div>{getProperty(current, column.field)}</div>
                       </div>
                   )}
                })}
            </div>
            <div style={{display: "grid", gridTemplateColumns: "100px auto"}}>
                <div style={{textAlign:"left"}}>
                    <Button text icon="pi pi-check" className="p-button-success"
                        onClick={(e) => {if (onSave) onSave({index:rowIndex, newData:current})}}
                    />
                </div>
                <div style={{textAlign:"right"}}>
                    <Button text icon="pi pi-times" className="p-button-secondary"
                        onClick={(e) => {
                            console.log("onCancel")
                            console.log(onCancel)
                            if (onCancel) onCancel({index:rowIndex, data:value})}
                        }
                    />
                </div>
            </div>
        </Card>
)
}

export default EditableCard
