import React, {useContext, useEffect, useState} from 'react';
import {Panel} from "primereact/panel";
import {Controller, useForm, useFormState} from "react-hook-form";
import {InputText} from "primereact/inputtext";
import {classNames} from "primereact/utils";
import {Password} from "primereact/password";
import {Button} from "primereact/button";
import {InputMask} from "primereact/inputmask";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Dropdown} from "primereact/dropdown";
import {AppContext} from "../../../../app/AppContext";
import {Dialog} from "primereact/dialog";
import OrgsList from "../../../Org/ui/OrgsList";
import {BorderedListItemEditor, ClearButtonOutlined} from "../../../../shared/ui/Styles";
import {Checkbox} from "primereact/checkbox";
import {InputTextarea} from "primereact/inputtextarea";
import AreasList from "../../../Area/ui/AreasList";
import SpecialityList from "../../../Speciality/ui/SpecialityList";


function User(props) {

    const {user, onSaveUser} = props

    const context = useContext(AppContext)

    const {control, handleSubmit, reset, watch, formState:{errors}, setValue, getValues} = useForm({
        defaultValues:{
            login: "",
            password: "",
            person:{
                id: undefined,
                name: "",
                secondName: "",
                middleName: "",
                email:"",
                phone:""
            },
            organization: undefined,
            cert:undefined,
            speciality:undefined,
            roles:[],
            approved: false
        }
    })
    const {isDirty} = useFormState({control})

    const [selectedRole, setSelectedRole] = useState(undefined)
    const [roles, setRoles] = useState([])
    const [rolePresentation, setRolePresentation] = useState([])
    const [addRoleDisabled, setAddRoleDisabled] = useState(true)
    const [showPasswordDialog, setShowPasswordDialog] = useState(false)
    const [newPassword, setNewPassword] = useState("")
    const [displayOrgsList, setDisplayOrgsList] = useState(false)
    const [displaySpecialityList, setDisplaySpecialityList] = useState(false);
    const [showAreasDialog, setShowAreasDialog] = useState(false);

    const loadRoles = () =>{
        context.apiService.getUserRoles().then(data =>{
            setRolePresentation(data.map((i)=>({value:i.id, label:i.description})))
            setRoles(data)
        })
    }

    const userGrid = {display:"grid", gridTemplateColumns:"150px 1fr", gridRowGap:"0.5rem"}
    const rolesGrid = {display:"grid", gridTemplateColumns:"1fr 150px", gridRowGap:"0.5rem"}

    useEffect(() => {
        reset({...user})
    }, [reset, user]);

    useEffect(()=>{
        loadRoles()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const userRoles = watch("roles")

    const changeRole = (e) =>{
        setSelectedRole(e.value)
        if (userRoles){
            setAddRoleDisabled(userRoles.find(r=>{return r.id === e.value})!==undefined)
        }
    }

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const addRole = (role) =>{
        if (!userRoles.find(i=>i.id===role.id)){
            userRoles.push(role)
            setValue("roles", userRoles, {shouldDirty:true})
            setAddRoleDisabled(true)
        }
    }

    const passwordDialogFooter = (
        <React.Fragment>
            <Button
                label="Установить новый пароль"
                onClick={()=>{
                    context.apiService.changePassword(user, newPassword).then(data =>{
                        if (data===true){ setShowPasswordDialog(false)}
                    })
                }}
            />
        </React.Fragment>
    );

    const actionBodyTemplate = (rowData)=>{
        return (<div>
            <ClearButtonOutlined
                style={{width:"20px",height:"20px"}}
                className="ml-4 p-button-text"
                type="button"
                onClick = {()=>{
                    let roles = getValues().roles.filter(i=>i.id!==rowData.id)
                    setValue("roles", roles, {shouldDirty:true})
                }}
            />
        </div>)
    }

    return (
        <form onSubmit={handleSubmit(onSaveUser)} style={{width:"100%"}} className="p-fluid">

            <OrgsList
                visible={displayOrgsList}
                onHide={()=>{setDisplayOrgsList(false)}}
                onSelectItem={(data)=>{
                    setValue("organization", data, { shouldDirty: true })
                    setDisplayOrgsList(false)}
                }
            />

            <SpecialityList
                visible={displaySpecialityList}
                onHide={()=>{setDisplaySpecialityList(false)}}
                onSelectItem={(data)=>{
                    setValue("speciality", data, { shouldDirty: true })
                    setDisplaySpecialityList(false)}
                }
            />

            <AreasList
                visible={showAreasDialog}
                onHide={()=>{setShowAreasDialog(false)}}
                onSelectItem={(data)=>{
                    setValue("area", data, {shouldDirty:true})
                    setShowAreasDialog(false)
                }}
            />

            { user && <Panel
                className="mt-2"
                header={`Информация о пользователе`}
            >
                <div style={userGrid}>

                    <div>
                        Логин
                    </div>
                    <div className="ml-1">
                        <Controller
                            name="login"
                            control={control}
                            rules={{ required: 'Логин обязателен к заполнению' }}
                            render={({field, fieldState})=>(
                                <InputText
                                    value={field.value || ""}
                                    onChange={field.onChange}
                                    //onBlur={field.onBlur}
                                    className={classNames({ 'p-invalid': fieldState.invalid })}
                                    style={{width:"100%"}}
                                />
                            )}
                        />
                        {getFormErrorMessage("login")}
                    </div>

                    <div>
                        Пароль
                    </div>
                    {user.id === undefined && <div className="ml-1">
                        <Controller
                            name={"password"}
                            control={control}
                            rules={{ required: 'Пароль обязателен к заполнению' }}
                            render={({field, fieldState})=>(
                            <Password
                                {...field}
                                toggleMask={true}
                                className={classNames({ 'p-invalid': fieldState.invalid })}
                            />
                        )}/>
                        {getFormErrorMessage("password")}
                    </div>}

                    <Dialog visible={showPasswordDialog}
                        onHide={()=>{setShowPasswordDialog(false)}}
                        footer={passwordDialogFooter}
                        style={{height:"230px"}}
                    >
                        <div><b>Новый пароль</b></div>
                        <Password
                            className="mt-1"
                            inputStyle={{width:"300px"}}
                            value={newPassword}
                            onChange={(e)=>{setNewPassword(e.target.value)}}
                        />
                    </Dialog>

                    {user.id && <div>
                        <Button className="ml-1" type="button" label="Сбросить пароль" onClick={()=>{setShowPasswordDialog(true)}}/>
                    </div>}

                    <div>
                        Фамилия
                    </div>
                    <div className="ml-1">
                        <Controller
                            name="person.surname"
                            control={control}
                            render={({field})=>(
                                <InputText
                                    value={field.value || ""}
                                    onChange={field.onChange}
                                    //onBlur={field.onBlur}
                                    style={{width:"100%"}}
                                />
                            )}
                        />
                    </div>

                    <div>
                        Имя
                    </div>
                    <div className="ml-1">
                        <Controller
                            name="person.name"
                            control={control}
                            render={({field})=>(
                                <InputText
                                    value={field.value || ""}
                                    onChange={field.onChange}
                                    //onBlur={field.onBlur}
                                    style={{width:"100%"}}
                                />
                            )}
                        />
                    </div>

                    <div>
                        Отчество
                    </div>
                    <div className="ml-1">
                        <Controller
                            name="person.patronymic"
                            control={control}
                            render={({field})=>(
                                <InputText
                                    value={field.value || ""}
                                    onChange={field.onChange}
                                    //onBlur={field.onBlur}
                                    style={{width:"100%"}}
                                />
                            )}
                        />
                    </div>

                    <div>
                        e - mail
                    </div>
                    <div className="ml-1">
                        <Controller
                            name="person.email"
                            control={control}
                            render={({field}) =>(
                                <InputText
                                    value={field.value || ""}
                                    onChange={field.onChange}
                                    //onBlur={field.onBlur}
                                    style={{width:"100%"}}
                                />
                            )}
                        />
                    </div>

                    <div>
                        Телефон
                    </div>
                    <div className="ml-1">
                        <Controller
                            name="person.phone"
                            control={control}
                            render={({field}) =>(
                                <InputMask
                                    mask="+7 (999) 999 99 99"
                                    value={field.value || ""}
                                    onChange={field.onChange}
                                    //onBlur={field.onBlur}
                                    style={{width:"100%"}}
                                />
                            )}
                        />
                    </div>

                    <div>
                        Специальность
                    </div>
                    <div className="ml-1">
                        <Controller
                            name="speciality"
                            control={control}
                            render={({field}) =>(
                                <BorderedListItemEditor
                                    value={field.value?.name || ""}
                                    onSelect={()=>{setDisplaySpecialityList(true)}}
                                    onClear={()=>{setValue("speciality", undefined, {shouldDirty: true})}}
                                />
                            )}
                        />
                    </div>

                    <div>
                        Сертификат
                    </div>
                    <div className="ml-1">
                        <Controller
                            name="cert"
                            control={control}
                            render={({field})=>(
                                <InputText
                                    value={field.value || ""}
                                    onChange={field.onChange}
                                    //onBlur={field.onBlur}
                                    style={{width:"100%"}}
                                />
                            )}
                        />
                    </div>

                    <div>
                        Организация
                    </div>
                    <div className="ml-1">
                        <Controller
                            name="organization"
                            control={control}
                            render={({field}) =>(
                                <BorderedListItemEditor
                                    value={field.value?.name || ""}
                                    onSelect={()=>{setDisplayOrgsList(true)}}
                                    onClear={()=>{setValue("organization", undefined, {shouldDirty: true})}}
                                />
                            )}
                        />
                    </div>
                    <div>
                        Область
                    </div>
                    <div className="ml-1">
                        <Controller
                            name="area"
                            control={control}
                            render={({field}) =>(
                                <BorderedListItemEditor
                                    value={field.value?.name || ""}
                                    onSelect={()=>{setShowAreasDialog(true)}}
                                    onClear={()=>{setValue("area", undefined, {shouldDirty: true})}}
                                />
                            )}
                        />
                    </div>
                    <div>
                        Коментарий
                    </div>
                    <div className="ml-1">
                        <Controller
                            name="comment"
                            control={control}
                            render={({field}) =>(
                                <InputTextarea
                                    value={field.value || ""}
                                    onChange={field.onChange}
                                    style={{width:"100%"}}
                                />
                            )}
                        />
                    </div>
                    <div>
                        Доступ разрешен
                    </div>
                    <div>
                        <Controller
                            name="approved"
                            control={control}
                            render={({field})=>(
                                <Checkbox
                                    checked={field.value}
                                    onChange={(e)=>{setValue("approved", e.checked, {shouldDirty:true})}}
                                    {...field}
                                />
                            )}
                        />
                    </div>

                </div>


                <Panel className="mt-1">
                    <div className="mt-1">
                        <div className="ml-1">
                            <Controller
                                name="roles"
                                control={control}
                                render={({field})=>(
                                    <DataTable
                                        style={{width:"100%"}}
                                        value={field.value}
                                        emptyMessage="Роли не назначены"
                                    >
                                        <Column field={"description"}  header="Роль"/>
                                        <Column body={actionBodyTemplate} style={{width:"5rem"}}/>
                                    </DataTable>
                                )} />
                        </div>
                    </div>
                    <div style={rolesGrid}>
                        <Dropdown
                            className="ml-1 mt-1"
                            value={selectedRole}
                            options={rolePresentation}
                            placeholder={"Роль"}
                            onChange={changeRole}
                        />
                        <Button
                            className="mt-1 ml-1"
                            label="Добавить"
                            icon="pi pi-plus"
                            disabled={addRoleDisabled || !selectedRole }
                            onClick={(e)=>{e.preventDefault(); addRole(roles.find(i=>i.id===selectedRole))}}
                        />
                    </div>

                </Panel>
                {isDirty && <div className="mt-1" style={{display:"flex",alignItems: "center", justifyContent: "center"}}>
                    <Button
                        className="p-button-success"
                        label="Сохранить изменения"
                        icon={"pi pi-save"}
                        type="submit"
                    />
                </div>}

            </Panel>
            }
        </form>
    );
}

export default User;
