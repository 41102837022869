import React, {useState} from 'react';
import PropTypes from "prop-types";

function PasswordEditor(props) {
    const [togglePasswordMask, setTogglePasswordMask] = useState(true)
    const {value, placeholder, onChange, onToggleMask, toggleMask} = {...props}
    return (
        <div
            className="flex align-items-center p-passeditor"
        >
            <input
                className="p-inputtext"
                type={togglePasswordMask ? "password": "text"}
                value={value}
                placeholder={placeholder}
                onChange={onChange}
            />
            {toggleMask && <div
              style={{paddingRight:"4px", paddingLeft:"2px"}}
              className={togglePasswordMask ? "pi pi-eye" : "pi pi-eye-slash"}
              onClick={()=>{
                  setTogglePasswordMask(!togglePasswordMask)
                  if (onToggleMask) onToggleMask(!togglePasswordMask)
              }}
            />}
        </div>
    );
}

PasswordEditor.protoTypes = {
    value: PropTypes.string,
    placeholder: PropTypes.string,
    toggleMask: PropTypes.bool,
    onChange: PropTypes.func,
    onToggleMask: PropTypes.func
}

export default PasswordEditor;