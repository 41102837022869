import {appState} from "../../../app/model/AppState";
import {Route} from "react-router-dom";
import DeniedLabel from "../../../shared/ui/DeniedLabel";
import React from "react";

function RestrictedRoute({ children, ...rest }) {
    if (!appState.user?.roles || appState.user.roles===[]) return (<></>)
    return (
        <Route
            {...rest}
            render = {({ location }) =>
                appState.user.roles.find(i=>i.level>=rest.level) ? (
                    children
                ) : (
                    <DeniedLabel/>
                )
            }
        />
    );
}

export default RestrictedRoute