import React from 'react'
import {getProperty} from './utils'
import PropTypes from "prop-types";
import {Button} from "primereact/button";

function ReadOnlyRow({columns, value, rowIndex, onRowEdit, onRowDelete}) {
    const createColumn = (column, rowIndex)=>{
        if (!column) return null;
        if (column.rowEditor){
            return <>
                <div style={{textAlign: 'center'}}>
                    <Button
                        icon="pi pi-pencil"
                        rounded text
                        className="mr-2"
                        onClick={() => { if (onRowEdit) onRowEdit({index:rowIndex, data:value})}}
                    />
                </div>    
            </>
        }
        if (column.rowDeleter){
            return <div style={{textAlign: 'center'}}>
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger"
                    onClick={() => {if (onRowDelete) onRowDelete(value)}}
                />
            </div>    
        }
        return (
            <>{column.body ? column.body(value) : getProperty(value, column.field)}</>
        )
    }

    return (
        <tr>
            {columns.map((column, index) => {
                return (
                    <td style={column?.style} key={index}>{createColumn(column, rowIndex)}</td>
                )
            })}
        </tr>
    )
}

ReadOnlyRow.propTypes = {
    columns: PropTypes.array.isRequired,
    value: PropTypes.object.isRequired,
}
export default ReadOnlyRow
