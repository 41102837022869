export const getProperty = (obj, prop) => {

    if (!prop || !obj) return undefined;
    if (typeof obj !== 'object') return undefined;

    const props = prop.split('.');
    let result = obj;
    for (let i = 0; i < props.length; i++) {
        result = result[props[i]];
        if (!result) return undefined;
    }
    return result;
}

export const setProperty = (obj, prop, value) => {
    if (!prop || !obj) return;
    if (typeof obj !== 'object') return;
    const props = prop.split('.');
    let result = obj;
    for (let i = 0; i < props.length; i++) {
        if (i === props.length - 1) {
            result[props[i]] = value;
        } else {
            result = result[props[i]];
        }
    }
}

export const setProperties = (obj, props) => {
    if (!props || !obj) return;
    if (typeof obj !== 'object') return;
    for (let prop in props) {
        setProperty(obj, prop, props[prop]);
    }
}