import {format, parseISO} from "date-fns";
import React, {useEffect, useState} from 'react'
import {observer} from "mobx-react-lite";
import {getSnapshot} from "mobx-state-tree";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Paginator} from "primereact/paginator";
import {Button} from "primereact/button";
import {BlockUI} from "../../../../shared/ui/BlockUI";
import {usePrescriptionsStore} from "../../model/PrescriptionsStore";
import {useMatchMedia} from "../../../../shared/lib/useMatch";
import PrescriptionDialog from "../../../../pages/DoctorUI/ui/PatientCard/PrescriptionDialog";


function PrescriptionList(props) {
    const { store, patient_id } = props;

    const [prescriptionsParams, setPrescriptionsParams] = useState({
        first: 0,
        rows: 10,
        page: 0,
    })

    const prescriptionStore = usePrescriptionsStore();
    const [showPrescriptionDialog, setShowPrescriptionDialog] = useState(false)
    const {isMobile} = useMatchMedia();

    useEffect(()=>{
        prescriptionStore.fetch({
            ...prescriptionsParams,
            patient_id: patient_id,
            doctor_id: store.doctorId
        })
    },[prescriptionStore, prescriptionsParams, patient_id, store.doctorId])

    const dateBody = (rowData) =>{
        return format(parseISO(rowData.date) ,"dd.MM.yyyy")
    }

    const onPageChange = (e)=>{
        setPrescriptionsParams({...prescriptionsParams, first:e.first, rows:e.rows, page: e.page })
    }

    return (
        <div>
            {showPrescriptionDialog &&<PrescriptionDialog
                visible={showPrescriptionDialog} onHide={()=>setShowPrescriptionDialog(false)}
                onSave={(data)=>{prescriptionStore.updatePrescription(data)}}
            />}
            <BlockUI blocking={prescriptionStore.isLoading} >
                {!prescriptionStore.isLoading && <div>
                    <DataTable
                        header={"Назначения"}
                        value={prescriptionStore.items}
                        selection={prescriptionStore.selected}
                        selectionMode="single"
                        onSelectionChange={(e)=>{
                            if (e.value){
                                prescriptionStore.setSelected(e.value)
                                prescriptionStore.initEditable(getSnapshot(e.value))
                                setShowPrescriptionDialog(true)
                            }
                        }}
                        emptyMessage={"Назначений еще нет"}
                        onClick={(e)=>{
                            prescriptionStore.initEditable(getSnapshot(prescriptionStore.selected))
                            setShowPrescriptionDialog(true)
                        }}
                    >
                        <Column body={dateBody} header={"Дата "}/>
                        <Column field={"id"} header={"Номер"}/>
                        <Column field={"description"} header={"Состав"}/>
                    </DataTable>
                    <Paginator
                        rows={prescriptionsParams.rows}
                        first={prescriptionsParams.first}
                        totalRecords={prescriptionStore.total}
                        onPageChange={onPageChange}
                        leftContent={()=>(
                            <Button icon={isMobile?"pi pi-plus":""} label={isMobile?"" : "Выписать назначение"}  rounded={isMobile} onClick={()=>{
                                prescriptionStore.initEditable({items:[], patient:getSnapshot(store.patient), date:new Date().toISOString()})
                                setShowPrescriptionDialog(true)}
                            } />
                        )}
                    />
                </div>}
        </BlockUI></div>
    )
}

export default observer(PrescriptionList)
