import {types} from "mobx-state-tree"
import {Component} from "./Component";
import ApiService from "../../../shared/api/ApiService";

export const ComponentsStore = types.model({
    items: types.array(Component),
    selected: types.safeReference(Component),
    isLoading: false,
    isDirty: false,
    total: types.maybe(types.number),
    error: ""
}).actions(self =>({
    setItems(items) {
        self.items = items
    },
    fetch(params) {
        self.isLoading = true;
        ApiService.getRpComponents(params).then(data => {
            self.fetchSuccess(data)
        }).catch(e => {
            self.fetchError(e)
        })
    },
    fetchSuccess(data) {
        if (data.content) {
            self.setItems(data.content)
        }
        self.total = data.totalElements
        self.error = ""
        self.isLoading = false
    },
    fetchError(error) {
        self.error = error.message
        self.isLoading = false
    },
    setSelected(selected) {
        self.selected = selected
    },
    saveAndSelect(component){
        if (component){
            ApiService.saveRpComponent(component).then(data =>{
                self.fetchSaveResult(data)
            }).catch(e =>{
                self.fetchError(e)
            })
        }
    },
    fetchDeleteSuccess(){
        let component = self.items.find(i=>i.id === self.editableItem.id)
        self.items.remove(component)
        self.error = ""
        self.isLoading = false
    },
    deleteComponent(component){
        self.isLoading = true
        self.editableItem = component
        if (component){
            ApiService.deleteRpComponent(component).then(()=>{
                self.fetchDeleteSuccess()
            }).catch(e=>{
                self.fetchError(e)
            })
        }
    },
    fetchSaveResult(data){
        if (data){
            let component= Component.create(data)
            let index = self.items.findIndex(i=>i.id === component.id )
            if (index>-1)
                self.items[index] = component
            else
                self.items.push(component)
            self.selected = component
            self.error = ""
        }
    },
    saveNewItem(){
        if (self.editableItem){
            self.saveAndSelect(self.editableItem)
            self.editableItem = null
        }
    }
}))

let _store
export const useComponentStore = ()=> {
    if (!_store) {
        _store = ComponentsStore.create({
            items: [],
        })
    }
    return _store;
}