import React, {useEffect} from 'react'
import {observer} from "mobx-react-lite";
import ToolBar from "../ToolBar";
import {useHistory, useParams} from "react-router-dom";
import {Card} from "primereact/card";
import {Button} from "primereact/button";
import PrescriptionList from "../../../../entyties/Prescription/ui/PrescriptionList/PrescriptionList";

function PatientCard(props) {
    let { id } = useParams();
    const store = props.store;
    const history = useHistory();

    useEffect(()=>{
        store.fetchPatient({patient_id:id, doctor_id:store.doctorId})
    },[id, store])

    const patientHeader = () =>(
        <div style={{display:"flex", alignItems:"center", justifyContent:"space-between", paddingTop:"5px", paddingLeft:"10px"}}>
            <div style={{display:"flex", alignItems:"center"}}>
                <span  style={{paddingLeft:"4px", fontSize:"1.2rem", fontWeight:"bold"}}>
                    Пациент: {store.patient.fullName}
                </span>
            </div>
            <div style={{paddingRight:"10px"}}>
                <Button rounded text icon={"pi pi-times"} onClick={()=>{history.push("/doctor")}}/>
            </div>
        </div>
    )

    if (!store.patient) return (<></>)
    return (
        <div>
            <ToolBar />
            <Card header={patientHeader}  className="m-2">
                <PrescriptionList store={store} patient_id={id} />
            </Card>
        </div>
    )
}

export default observer(PatientCard)
