import React from 'react';
import {InputText} from "primereact/inputtext";

function SearchInput(props) {
    return (
        <div className={props.className}>
            <div className="container p-1">
                <div className="p-input-icon-left p-d-block" >
                    <i className="pi pi-search ml-1" />
                    <InputText {...props} />
                </div>
            </div>
        </div>
    );
}

export default SearchInput;