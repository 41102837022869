import React, {useRef} from 'react'
import {Dialog} from "primereact/dialog";
import PrescriptionEditor from "../../../../entyties/Prescription/ui/PrescriptionEditor";
import {Stepper} from "primereact/stepper";
import {StepperPanel} from "primereact/stepperpanel";
import MonitoringEditor from "../../../../entyties/Monitoring/ui/MonitoringEditor";
import {usePrescriptionsStore} from "../../../../entyties/Prescription/model/PrescriptionsStore";
import {observer} from "mobx-react-lite";

function PrescriptionDialog(props) {
    const stepperRef = useRef(null);
    const prescriptionStore = usePrescriptionsStore()
    const isReadyToShow = ()=>{
        return !prescriptionStore.isLoading && prescriptionStore.editable
    }
    return (
        <Dialog header={"Назначение"} onHide={props.onHide} visible={props.visible}
            style={{ width: '90%' }} breakpoints={{ '1200px': '100%' }}
        >
            { isReadyToShow() && prescriptionStore.editable.sentToPharmacy && <Stepper ref={stepperRef}>
                <StepperPanel header="Пропись">
                    <PrescriptionEditor onSave={props.onSave}/>
                </StepperPanel>
                <StepperPanel header="Наблюдение" >
                    <MonitoringEditor onSave={props.onSave}/>
                </StepperPanel>
            </Stepper> }
            {isReadyToShow() && !prescriptionStore.editable.sentToPharmacy &&
                <PrescriptionEditor onSave={props.onSave}/>
            }
        </Dialog>
    )
}

export default observer(PrescriptionDialog)
