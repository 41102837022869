import { types } from "mobx-state-tree"
import {Person} from "../../Person/model/Person";
import {Organization} from "../../Org/model/Organization";
import {Role} from "../../Role/model/Role";
import {Area} from "../../Area/model/Area";
import {Speciality} from "../../Speciality/model/Speciality";

export const User = types.model({
    id: types.number,
    login: types.string,
    regDate: types.maybeNull(types.string),
    person: types.maybeNull(Person),
    organization: types.maybeNull(Organization),
    roles: types.array(Role),
    area: types.maybeNull(Area),
    speciality: types.maybeNull(Speciality),
    cert: types.maybeNull(types.string)
}).views(self => ({
    get fullName(){
        return self.person ? `${self.person.surname} ${self.person.name}` : self.login
    },
    get isAdmin(){
        if (self.roles)
            return self.roles.findIndex(i=>i.name==="ROLE_ADMIN") !== -1
        return false
    },
    get isDoctor(){
        if (self.roles)
            return self.roles.findIndex(i=>i.name==="ROLE_DOCTOR") !== -1
        return false
    },
    get isPharmacist(){
        if (self.roles)
            return self.roles.findIndex(i=>i.name==="ROLE_PHARMACIST") !== -1
        return false
    },
    get reg_date(){
        return new Date(self.regDate)
    }
}))

