import { types } from "mobx-state-tree"
import {formatISO} from "date-fns";


export const PersonCore = types.model({
    surname: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    patronymic: types.maybeNull(types.string),
    birthDate: types.maybeNull(types.string),
    gender: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    snils: types.maybeNull(types.string),
    weight: types.maybeNull(types.number),
}).volatile(self=>({
    _isDirty: false,
})).actions(self => ({

    setSurname(surname){
        self.surname = surname
        self._isDirty = true
    },

    setName(name){
        self.name = name
        self._isDirty = true
    },

    setPatronymic(patronymic){
        self.patronymic = patronymic
        self._isDirty = true
    },

    setBirthDate(date){
        if (typeof date === 'string' || date instanceof String)
            self.birthDate = date
        else
            self.birthDate = formatISO(date, { representation: 'complete' })
        self._isDirty = true
    },

    setGender(gender){
        self.gender = gender
        self._isDirty = true
    },

    setPhone(phone){
        self.phone = phone
        self._isDirty = true
    },

    setEmail(email){
        self.email = email
        self._isDirty = true
    },

    setSnils(snils){
        self.snils = snils
        self._isDirty = true
    },

    setWeight(weight){
        self.weight = weight
        self._isDirty = true
    },
    clearIsDirty(){
        self._isDirty = false;
    }

})).views(self=>({
    get fullName(){
        return `${self.surname} ${self.name!=="" ? self.name[0]+".": ""} ${self.patronymic!=="" ? self.patronymic[0]+".": ""}`
    },
    get isDirty(){
        return self._isDirty
    }
}))

export const Person = types.compose(
    types.model({
        id: types.identifierNumber
    }), PersonCore
)

export const PersonEditable = types.compose(
    types.model({
        id: types.maybeNull(types.number)
    })
)

// export const Person = types.snapshotProcessor(BasePerson, {
//     preProcessor(snapshot) {
//         return {
//             ...snapshot,
//             birthDate: snapshot.birthDate ? parseISO(snapshot.birthDate): null
//         }
//     },
//     postProcessor(snapshot) {
//         return {
//             ...snapshot,
//             birthDate: snapshot.birthDate ? formatISO(snapshot.birthDate): "null"
//         }
//     }
// })
