import React, {useContext} from 'react';
import {AppContext} from "../../../../app/AppContext";
import UniversalList from "../../../../shared/ui/UniversalList";
import PropTypes from "prop-types";
import PersonFilter from "./PersonFilter";
import PersonEditor from "./PersonEditor";

function PatientList(props) {
    const context = useContext(AppContext)
    const itemsService = context.apiService

    const fullNameBodyTemplate = (rowData) => {
        return `${rowData.person.surname} ${rowData.person.name[0]}. ${rowData.person.patronymic[0]}.`;
    }

    return (
        <div>
            <UniversalList
                header="Пациенты"
                visible={props.visible}
                //filtersTemplate={filterTemplate}
                filtersTemplate={PersonFilter}
                //rowViewerTemplate={PatientViewer}
                rowEditorTemplate={PersonEditor}
                fullPageEditor={true}
                onHide={props.onHide}
                onGetData={itemsService.getPatients.bind(itemsService)}
                onSelectItem={props.onSelectItem}
                onEditRow={(row)=>{console.log(row)}}
                //onDeleteRow={(row)=>{console.log(row)}}
                onAddRow={(row)=>{console.log(row)}}
                columns = {[
                    {id:1, body:fullNameBodyTemplate, header:"Ф.И.О"},
                    {id:2, field:"person.snils", header:"СНИЛС"},
                    {id:3, field:"person.phone", header:"Телефон"}
                ]}
            />

        </div>
    );
}

PatientList.propTypes = {
    onSelectItem: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired
}

export default PatientList;
