import React from 'react'
import {InputText} from "primereact/inputtext";

function Mkb10Filter(props) {

    const {params, setParams} = props

    return (
        <div className="p-input-icon-left p-d-block" >
            <span className="p-input-icon-left mr-2 mt-4 p-float-label">
                <i className="pi pi-search" />
                <InputText value={params.code || ""} className=""
                           onChange ={(e) => {setParams({...params, code:e.target.value})}}
                           autoFocus/>
                <label>Код</label>
            </span>
            <span className="p-input-icon-left mr-2 p-float-label">
                <i className="pi pi-search" />
                <InputText value={params.description || ""} className=""
                           onChange ={(e) => {setParams({...params, description:e.target.value})}}
                />
                <label>Описание</label>
            </span>
        </div>
    )
}

export default Mkb10Filter
