import {types} from "mobx-state-tree"
import ApiService from "../../../shared/api/ApiService";
import {RpType} from "./RpType";

export const RpTypeStore = types.model({
    items: types.array(RpType),
    selected: types.safeReference(RpType),
    isLoading: false,
    isDirty: false,
    total: types.maybe(types.number),
    error: ""
}).actions(self =>({
    setItems(items) {
        self.items = items
    },
    fetch(params) {
        self.isLoading = true;
        ApiService.getRpTypeList(params).then(data => {
            self.fetchSuccess(data)
        }).catch(e => {
            self.fetchError(e)
        })
    },
    fetchSuccess(data) {
        if (data.content) {
            self.setItems(data.content)
        }
        self.total = data.totalElements
        self.error = ""
        self.isLoading = false
    },
    fetchError(error) {
        self.error = error.message
        self.isLoading = false
    },
    setSelected(selected) {
        self.selected = selected
    },
    saveAndSelect(item){
        if (item){
            ApiService.saveRpType(item).then(data =>{
                self.fetchSaveResult(data)
            }).catch(e =>{
                self.fetchError(e)
            })
        }
    },
    fetchDeleteSuccess(){
        let component = self.items.find(i=>i.id === self.editableItem.id)
        self.items.remove(component)
        self.error = ""
        self.isLoading = false
    },
    deleteItem(item){
        self.isLoading = true
        self.editableItem = item
        if (item){
            ApiService.deleteRpType(item).then(()=>{
                self.fetchDeleteSuccess()
            }).catch(e=>{
                self.fetchError(e)
            })
        }
    },
    fetchSaveResult(data){
        if (data){
            let item= RpType.create(data)
            let index = self.items.findIndex(i=>i.id === item.id )
            if (index>-1)
                self.items[index] = item
            else
                self.items.push(item)
            self.selected = item
            self.error = ""
        }
    },
    saveNewItem(){
        if (self.editableItem){
            self.saveAndSelect(self.editableItem)
            self.editableItem = null
        }
    }
}))

let _store
export const useRpTypeStore = ()=> {
    if (!_store) {
        _store = RpTypeStore.create({
            items: [],
        })
    }
    return _store;
}