import { types } from "mobx-state-tree"

export const Component = types.model({
    id: types.identifierNumber,
    name: types.string,
    ru_name: types.maybeNull(types.string),
    en_name: types.maybeNull(types.string)
})


