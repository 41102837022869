import React from 'react'
import ChipsList from "../../../../shared/ui/ChipsList";
import ResponsiveGrid from "../../../../shared/ui/ResponsiveGrid";
import {Column} from "primereact/column";

function RpMobile(props) {
    const {rp} = props;
    const grid = {display: "grid", gridTemplateColumns: "150px 1fr", gridRowGap: "0.5rem"}
    const centeredLabel = {display: "flex", alignItems: "center", fontWeight: "bold"}


    const concentrationBody = (rowData) => {
        if (rowData.concentration) {
            return `${rowData.concentration} %`
        }
    }

    const volumeBody = (rowData) => {
        if (rowData.volume) {
            return `${rowData.volume}`
        }
    }

    const quantTypeBody = (rowData) => {
        if (rowData.quantType) {
            return `${rowData.quantType.name}`
        }
    }

    const abbreviationBody = (rowData) => {
        if (rowData.abbreviation) {
            return `${rowData.abbreviation.name}`
        }
    }

    return (
        <div>

            <div style={grid}>
                <div style={centeredLabel}>
                    Наименование :
                </div>
                <div>
                    {rp.name}
                </div>
                <div style={centeredLabel}>Область :</div>
                <ChipsList
                    readOnly={true}
                    values={rp.areas}
                />
                <div style={centeredLabel}>
                    Тип действия :
                </div>
                <ChipsList
                    readOnly={true}
                    values={rp.type}
                />
                <div style={centeredLabel}>
                    ATX :
                </div>
                <ChipsList
                    readOnly={true}
                    values={rp.ath}
                />
                <div style={centeredLabel}>
                    МКБ10 :
                </div>
                <ChipsList
                    readOnly={true}
                    values={rp.mkb10}
                />
                <div style={centeredLabel}>
                    Форма выпуска
                </div>
                <div>{rp.lecForm?.name}</div>
            </div>
            <div style={centeredLabel}>
                Rp. :
            </div>
            <ResponsiveGrid values={rp.items}>
                <Column header="Компонент" field="component.name"
                        headerStyle={{minWidth: '8rem'}}/>
                <Column header="Концентрация" style={{width: '120px'}} field="concentration"
                        body={concentrationBody}
                />
                <Column header="Сокращения" style={{width: '100px'}} field="abbreviation"
                        body={abbreviationBody}
                />
                <Column header="Кол-во" style={{width: '90px'}} field="quant"/>
                <Column header="Ед. измерения" style={{width: '120px'}} field="quantType"
                        body={quantTypeBody}
                />
                <Column header="Объем" style={{width: '50px'}} field="volume"
                        body={volumeBody}
                />
            </ResponsiveGrid>
            <div className={"mt-2"} style={centeredLabel}>
                M.D.S. :
            </div>
            <div>{rp.mds}</div>
            <div style={centeredLabel}>
                Действие и показания:
            </div>
            <div>{rp.action}</div>

        </div>

    )
}

export default RpMobile
