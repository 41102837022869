import React, {useEffect, useState} from 'react'
import {observer} from "mobx-react-lite";
import {InputText} from "primereact/inputtext";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {Label} from "../../../../shared/ui/Styles";

const grid = {display: "grid", gridTemplateColumns: "12rem 1fr",  margin: "4px"}

function EditorDialog(props) {

    const {visible, setVisible, component, onSaveRpType} = props
    const [item, setItem] = useState({})

    useEffect(() => {
        if (component)
            setItem(component)
    }, [component]);

    const footerContent = (
        <div>
            <Button label="Сохранить" icon="pi pi-check" onClick={() => {
                onSaveRpType(item)
                setVisible(false)
            }} type="submit" />
            <Button label="Отмена" icon="pi pi-trash" severity="danger" onClick={() => setVisible(false)} />
        </div>
    );

    return (
        <div>
            <Dialog
                header="Редактирование типа действий"
                footer={footerContent}
                visible={visible}
                onHide={()=>{setVisible(false)}}
            >
                <form>
                    <div style={grid}>
                        <Label>Тип действия:</Label>
                        <div>
                            <InputText
                                value={item?.name || ""}
                                autoFocus
                                onChange={(e)=>{
                                    setItem({...item,name:e.target.value})
                                }}
                            />
                        </div>
                    </div>
                </form>
            </Dialog>
        </div>
    )
}

export default observer(EditorDialog)
