import React, {useContext, useEffect, useState} from 'react';
import {Column} from "primereact/column";
import NumberFormat from "react-number-format";
import {Dropdown} from "primereact/dropdown";
import {AppContext} from "../../../../app/AppContext";
import {Button} from "primereact/button";
import ItemsList from "../../../../shared/ui/ItemsList";
import OneRowItemEditor from "../../../../shared/ui/ItemsList/ItemEditor";
import {Dialog} from "primereact/dialog";
import {observer} from "mobx-react-lite";
import PropTypes from "prop-types";
import ResponsiveGrid from "../../../../shared/ui/ResponsiveGrid";

function RpItemsEditor(props) {

    const {items, addItem, removeItem, readOnly, setIsEditing} = props

    const [quantTypes, setQuantTypes] = useState([])
    const [abbreviation, setAbbreviation] = useState([])

    const [componentForDeletion, setComponentForDeletion] = useState(undefined)
    const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false)
    const [displayComponentsList, setDisplayComponentsList] = useState(false)

    const [showComponentEditor, setShowComponentEditor] = useState(false)
    const [currentComponent, setCurrentComponent] = useState("")

    const context = useContext(AppContext)
    const itemsService = context.apiService

    const quantEditor = (options) => {
        return <NumberFormat
            className="p-inputtext"
            style={{width: "80px"}}
            value={options.value}
            allowedDecimalSeparators={[".", ","]}
            onValueChange={(e) => options.editorCallback(e.value)}
        />
    }

    const abbreviationEditor = (options) => {
        return (
            <Dropdown
                value={options.value?.id} options={abbreviation} optionLabel="name" optionValue="id"
                onChange={(e) => options.editorCallback(abbreviation.find(i => i.id === e.value))}
                style={{width: '100%'}}
                placeholder=""
                showClear={true}
                itemTemplate={(option) => {
                    return <span>{option.name}</span>
                }}
            />
        )
    }

    const concentrationEditor = (options) => {
        return <NumberFormat
            value={options.value}
            className="p-inputtext"
            style={{width: "100px"}}
            allowedDecimalSeparators={[".", ","]}
            onValueChange={(e) => options.editorCallback(e.value)}
            suffix=" %"
        />
    }

    const volumeEditor = (options) => {
        return <NumberFormat
            value={options.rowData['volume']}
            className="p-inputtext"
            style={{width: "80px"}}
            allowedDecimalSeparators={[".", ","]}
            onChange={(e) => options.editorCallback(e.target.value)}
        />
    }

    const quantTypeEditor = (props) => {
        return (
            <Dropdown value={props.value?.id} options={quantTypes} optionLabel="name" optionValue="id"
                      onChange={(e) => props.editorCallback(quantTypes.find((i) => {
                          return i.id === e.value
                      }))}
                      showClear={true}
                      style={{width: '100%'}}
                      placeholder=""
                      itemTemplate={(option) => {
                          return <span>{option.name}</span>
                      }}
            />
        );
    }

    const concentrationBody = (rowData) => {
        if (rowData.concentration) {
            return `${rowData.concentration} %`
        }
    }

    const volumeBody = (rowData) => {
        if (rowData.volume) {
            return `${rowData.volume}`
        }
    }

    const quantTypeBody = (rowData) => {
        if (rowData.quantType) {
            return `${rowData.quantType.name}`
        }
    }

    const abbreviationBody = (rowData) => {
        if (rowData.abbreviation) {
            return `${rowData.abbreviation.name}`
        }
    }

    const loadQuantTypes = () => {
        context.apiService.getRpQuantTypes().then(data => {
            setQuantTypes(data)
        })
    }

    const loadAbbreviation = () => {
        context.apiService.getAbbreviation().then(data => {
            setAbbreviation(data)
        })
    }

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            loadQuantTypes()
            loadAbbreviation()
        }
        return () => {
            isMounted = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onRowDelete = (rowData) => {
        setComponentForDeletion(rowData)
        setDisplayConfirmDialog(true)
    }

    const oneRowEditor = (<>
        <OneRowItemEditor
            onSave={(value) => {
                context.apiService.saveRpComponent({name: value})
                setCurrentComponent(value)
                setShowComponentEditor(false)
            }}
        />
    </>)

    const onRowEditComplete = (e) => {
        let {newData, index} = e
        console.log(newData)
        items[index].update(newData)
        setIsEditing(false)
    }

    const maxIdx = (arr) => (arr.reduce((p, v) => (p.serialNumber > v.serialNumber ? p.serialNumber : v.serialNumber), 0))

    const addComponent = (e) => {

        const serialNumber = items.length > 0 ? maxIdx(items) + 1 : 0
        addItem({
            id: undefined,
            serialNumber: serialNumber,
            concentration: undefined,
            quant: undefined,
            quantType: undefined,
            volume: undefined,
            component: {id: e.id, name: e.name}
        })

        setDisplayComponentsList(false)
    }

    const onDeleteComponent = () => {
        removeItem(componentForDeletion.serialNumber)
        setComponentForDeletion(undefined)
        setDisplayConfirmDialog(false)
    }

    const deleteProductDialogFooter = (
        <React.Fragment>
            <Button label="Нет" autoFocus={true} icon="pi pi-times" className="p-button-success" onClick={() => {
                setDisplayConfirmDialog(false)
            }}/>
            <Button label="Да" icon="pi pi-check" className="p-button-danger" onClick={() => {
                onDeleteComponent()
            }}/>
        </React.Fragment>
    );

    const onRowEditChange = (e) => {
        //setEditingRows(e.data);
        setIsEditing(Object.keys(e.data).length !== 0)
    }

    return (
        <div>
            <ItemsList
                header="Компонент"
                visible={displayComponentsList}
                onHide={() => {
                    setDisplayComponentsList(false)
                }}
                getData={itemsService.getRpComponents.bind(itemsService)}
                showEditor={showComponentEditor}
                setShowEditor={setShowComponentEditor}
                initialValue={currentComponent}
                editor={oneRowEditor}
                onAccept={addComponent}
            />

            <Dialog
                visible={displayConfirmDialog}
                style={{width: '450px'}}
                header="Внимание" modal
                footer={deleteProductDialogFooter}
                onHide={() => {
                    setDisplayConfirmDialog(false)
                }}
            >
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{fontSize: '2rem'}}/>
                    {componentForDeletion &&
                        <span>Вы действительно хотите удалить компонент <b>{componentForDeletion.component.name}</b>?</span>}
                </div>
            </Dialog>

            <ResponsiveGrid values={items}
                emptyMessage="Нет записей"
                onRowEditComplete={onRowEditComplete}
                onRowEditChange={onRowEditChange}
                onRowDelete={onRowDelete}
            >
                <Column header="Компонент" field="component.name"
                        headerStyle={{minWidth: '8rem'}}/>
                <Column header="Концентрация" style={{width: '120px'}} field="concentration"
                        body={concentrationBody}
                        editor={(props) => concentrationEditor(props)}/>
                <Column header="Сокращения" style={{width: '100px'}} field="abbreviation"
                        body={abbreviationBody}
                        editor={(props) => {
                            return abbreviationEditor(props)
                        }}
                />
                <Column header="Кол-во" style={{width: '90px'}} field="quant"
                        editor={(props) => quantEditor(props)}/>
                <Column header="Ед. измерения" style={{width: '120px'}} field="quantType"
                        body={quantTypeBody}
                        editor={(props) => quantTypeEditor(props)}/>
                <Column header="Объем" style={{width: '50px'}} field="volume"
                        body={volumeBody}
                        editor={(props) => volumeEditor(props)}/>
                {!readOnly &&
                    <Column rowEditor headerStyle={{width: '50px', minWidth: '7rem'}}
                            bodyStyle={{textAlign: 'center'}}/>}
                {!readOnly &&
                    <Column rowDeleter headerStyle={{width: '50px', minWidth: '7rem'}}
                            bodyStyle={{textAlign: 'center'}}/>}

            </ResponsiveGrid>

            {!readOnly && <div className="mt-2">
                <Button
                    icon="pi pi-plus"
                    label="Добавить компонент"
                    type="button"
                    onClick={() => {
                        setDisplayComponentsList(true)
                    }}
                />
            </div>}
        </div>
    )
}

RpItemsEditor.propTypes = {
    readOnly: PropTypes.bool,
    items: PropTypes.any,
    addItem: PropTypes.func,
    removeItem: PropTypes.func,
    setIsEditing: PropTypes.func
}

export default observer(RpItemsEditor)
