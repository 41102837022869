import {types} from "mobx-state-tree";
import {User} from "../../entyties/User/model/User";
import { asReduxStore, connectReduxDevtools } from "mst-middlewares"
import {DoctorUIState} from "../../pages/DoctorUI/model/DoctorUIState";
import {PharmacistUIState} from "../../pages/PharmacyUI/model/PharmacistUIState";
// import WebSocketApi from "../services/WebSocketApi";
// import configData from '../config.json'
import {Mkb10Store} from "../../entyties/Mkb10/model/Mkb10Store";

const Store = types.model({
    user: types.maybeNull(User),
    mkb10list: types.optional(Mkb10Store, {}),
    doctorUI: types.optional(DoctorUIState, {}),
    pharmacistUI: types.optional(PharmacistUIState, {})
}).actions(self => ({
    setUser(user){
        self.user = user
        if (user.person)
            self.doctorUI.setDoctorId(user.person.id)

        // self.ws = new WebSocketApi({
        //     url:`${configData.SERVER_URL}/websockets?token=${sessionStorage.getItem("token")}`,
        //     token:sessionStorage.getItem("token")
        // })

    }
})).volatile(self =>({
    ws: null
}))

export const appState = Store.create()
export const store = asReduxStore(appState)
//connectReduxDevtools(require("remotedev"), appState)
