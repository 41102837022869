import {Dialog} from "primereact/dialog";
import {DataTable} from "primereact/datatable";
import React, {useEffect, useState} from "react";
import {Column} from "primereact/column";
import {InputText} from "primereact/inputtext";
import {Panel} from "primereact/panel";
import {Button} from "primereact/button";
import 'react-block-ui/style.css';
import {BlockUI} from "../BlockUI";

const ItemsList = (props) =>{

    const {showEditor, setShowEditor, editor, initialValue} = props
    const [items, setItems] = useState([])
    const [loading, setLoading] = useState(false);
    const [totalElements, setTotalElements] = useState(0)

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 0,
        name: initialValue || "",
    })

    useEffect(()=>{
        if (props.clearPrevSearchItem && lazyParams.name!=="")
            setLazyParams({...lazyParams, name:""})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.visible])

    useEffect(()=>{
        setLazyParams({...lazyParams, name:initialValue || ""})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.initialValue])

    useEffect(()=>{
        let isMounted = true
        const loadLazyData = () => {
            setLoading(true);
            props.getData(lazyParams).then(data => {
                if (isMounted){
                    setTotalElements(data?.totalElements);
                    setItems(data?.content);
                    setLoading(false);
                }
            });
        }
        if (props.getData !== undefined && props.visible){
            loadLazyData();
        }
        return ()=>isMounted = false
    },[lazyParams, props])

    const onPage = (event) => {
        let _lazyParams = { ...lazyParams, ...event };
        setLazyParams(_lazyParams);
    }

    const onAccept = (event) => {
        if (props.onAccept !== undefined){
            props.onAccept(event.value)
        }
    }

    if (!props.visible) return <></>

    let columns = undefined
    if (props.columns){
        columns = props.columns.map((column) => {
            return <Column key="id" field={column.field} header={column.header} />
        })
    }

    return(
        <Dialog
            onHide={props.onHide}
            visible={props.visible}
            style={{ width: '80%' }}
        >

            <div className="container p-1">
                <div className="p-input-icon-left p-d-block" >
                    <i className="pi pi-search" />
                    <InputText
                        value={lazyParams.name} className=""
                        onChange ={(e) => {
                           setLazyParams({...lazyParams, first:0, page:0, name:e.target.value})}
                        }
                        placeholder="Поиск" autoFocus/>
                </div>
            </div>

            <div className="datatable-with-header">
                <BlockUI blocking={loading}>
                <DataTable
                    value={items} lazy
                    selectionMode="single"
                    onSelectionChange={onAccept}
                    paginator first={lazyParams.first} rows={lazyParams.rows} totalRecords={totalElements}
                    rowsPerPageOptions={[10,20]} onPage={onPage}
                    dataKey="id"
                >
                    {columns || <Column field="name" header="Наименование"/>}
                </DataTable>
                </BlockUI>
                {(props.editor && !showEditor) && <Panel className="mt-1">
                    <Button
                        label="Добавить позицию в список"
                        onClick={()=>{if(setShowEditor) setShowEditor(true)}}
                    />
                </Panel>}
            </div>
            {showEditor && editor}

        </Dialog>
    )
}

export default ItemsList
