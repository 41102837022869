import React, {useContext, useEffect} from 'react';
import ToolBar from "./ToolBar";
import {Switch} from "react-router-dom";
import RestrictedRoute from "../../../entyties/User/lib/RestrictedRoute";
import {AppContext} from "../../../app/AppContext";
import Users from "../../../entyties/User/ui/Users";
import RpList from "../../../entyties/Rp/ui/AdminRpList/RpList";
import Orgs from "../../../entyties/Org/ui/Orgs";
import Pharmacies from "../../../entyties/Pharmacy/ui/Pharmacies";
import {appState} from "../../../app/model/AppState";
import {User} from "../../../entyties/User/model/User";
import Mkb10 from "../../../entyties/Mkb10/ui/Mkb10AdmList";
import Components from "../../../entyties/Component/ui/ComponentsList";
import LecForms from "../../../entyties/LecForm/ui/LecFormsAdmList";
import RpType from "../../../entyties/RpType/ui/AdminRpTypeList";

const items = [
    {label: 'Пользователи', icon: 'pi pi-fw pi-users', page:"/admin"},
    {label: 'Медучреждения', icon: 'pi pi-fw pi-building', page:"/admin/org"},
    {label: 'Аптеки', icon: 'pi pi-fw pi-building', page:"/admin/pharmacy"},
    {label: 'Прописи', icon: 'pi pi-fw pi-calendar', page:"/admin/rp"},
    {label: 'МКБ10', icon: 'pi pi-fw pi-calendar', page:"/admin/mkb10"},
    {label: 'Компоненты', icon: 'pi pi-fw pi-calendar', page:"/admin/components"},
    {label: 'ЛФ', icon: 'pi pi-fw pi-calendar', page:"/admin/lec_forms"},
    {label: 'Типы действий', icon: 'pi pi-fw pi-book', page:"/admin/rp-types"},
    // {label: 'Documentation', icon: 'pi pi-fw pi-file'},
    // {label: 'Settings', icon: 'pi pi-fw pi-cog'}
];

function AppManager(props) {

    const context = useContext(AppContext)

    const loadUserData = () =>{
        //setLoading(true)
        if (sessionStorage.getItem("refresh_token") !== undefined)
            context.apiService.getUser(sessionStorage.getItem("refresh_token"))
                .then(data =>{
                    appState.setUser(User.create(data))
                    //setLoading(false)
                })
                .catch(e=>{console.log(e)})
    }

    useEffect(()=>{
        loadUserData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            <ToolBar items={items}/>
            <Switch>
                <RestrictedRoute path="/admin" exact level={10} ><Users/></RestrictedRoute>
                <RestrictedRoute path="/admin/org" exact level={10} ><Orgs/></RestrictedRoute>
                <RestrictedRoute path="/admin/pharmacy" exact level={10} ><Pharmacies/></RestrictedRoute>
                <RestrictedRoute path="/admin/rp" exact level={2}><RpList/></RestrictedRoute>
                <RestrictedRoute path="/admin/mkb10" exact level={2}><Mkb10/></RestrictedRoute>
                <RestrictedRoute path="/admin/components" exact level={2}><Components/></RestrictedRoute>
                <RestrictedRoute path="/admin/lec_forms" exact level={2}><LecForms/></RestrictedRoute>
                <RestrictedRoute path="/admin/rp-types" exact level={2}><RpType/></RestrictedRoute>
            </Switch>
        </div>
    );
}

export default AppManager;
