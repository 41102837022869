import * as React from 'react';
import {useContext} from "react";
import {AppContext} from "../../../app/AppContext";
import NameFilter from "../../../shared/ui/UniversalList/NameFilter";
import UniversalList from "../../../shared/ui/UniversalList";
import PropTypes from "prop-types";

function AreasList(props){

    const context = useContext(AppContext)
    const itemsService = context.apiService

    return (
        <div>
            <UniversalList
                header="Области"
                visible={props.visible}
                //filtersTemplate={filterTemplate}
                filtersTemplate={NameFilter}
                //rowViewerTemplate={rowViewTemplate}
                //rowEditorTemplate={rowEditorTemplate}
                onHide={props.onHide}
                onGetData={itemsService.getAreas.bind(itemsService)}
                onSelectItem={props.onSelectItem}
                onEditRow={(row)=>{console.log(row)}}
                onDeleteRow={(row)=>{console.log(row)}}
                onAddRow={(row)=>{console.log(row)}}
            />
        </div>
    );
}

AreasList.propTypes = {
    onSelectItem: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired
}

export default AreasList;