import React, {useContext, useEffect, useRef, useState} from 'react';
import {AppContext} from "../../../app/AppContext";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Panel} from "primereact/panel";
import {Button} from "primereact/button";
import {ClearButtonOutlined, DangerIconOnlyButton} from "../../../shared/ui/Styles";
import {Toast} from "primereact/toast";
import User from "./UserList/User";
import SplitPane from "react-split-pane";
import "../../../shared/scss/Resizer.css"
import SearchInput from "../../../shared/ui/SearchInput";
import {observer} from "mobx-react-lite";
import {BlockUI} from "../../../shared/ui/BlockUI";
import {parseISO} from "date-fns";

function Users(props) {

    const defaultValues = {
        login: "",
        password: "",
        person:{
            id: undefined,
            name: "",
            secondName: "",
            middleName: "",
            email:"",
            phone:""
        },
        organization: undefined,
        roles:[]
    }

    const toast = useRef(null)
    const context = useContext(AppContext)
    const [isLoading, setIsLoading] = useState(false)
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 0,
        login: undefined
    })
    const [items, setItems] = useState([]);
    const [total, setTotal] = useState(0);
    const [selectedUser, setSelectedUser] = useState(undefined);
    const [usersLocked, setUsersLocked] = useState(true);
    const dataTable = useRef(null);

    useEffect(()=>{
        let isMounted = true
        const loadData = () =>{
            setIsLoading(true)
            context.apiService.getUsers(lazyParams).then(data =>{
                if (isMounted){
                    setItems(data.content === undefined ? [] : data.content)
                    setTotal(data.totalElements)
                    setIsLoading(false)
                }
            })
        }
        loadData()
        return ()=>{isMounted=false}
    },[context.apiService, lazyParams])

    const usersHeader = (options) =>{
        const className = `${options.className} p-jc-center`;
        const titleClassName = `${options.titleClassName} pl-1`
        return(
            <div className={className} style={{textAlign:"center"}}>
                <span className={titleClassName} style={{width:"100%"}}>Пользователи</span>
                <span className={titleClassName}>
                    <DangerIconOnlyButton
                        icon={usersLocked ? "pi pi-lock" : "pi pi-unlock"}
                        className="mr-2 p-button-rounded p-button-danger p-button-text"
                        style={{fontSize:"6px", width:"1.5rem", height:"1.5rem"}}
                        onClick={()=>{
                            setUsersLocked(!usersLocked)
                        }}
                    />
                </span>
            </div>
        )
    }

    const saveUser = (user)=> {
        context.apiService.saveUser(user).then(data=>{
            setSelectedUser(data)
            if (items.find(i=>i.id === data.id))
                setItems(items.map(i=>{if (i.id === data.id) return data; else return i}))
            else {
                items.push(data)
                setItems([...items])
            }
        })
    }

    const createUser = () =>{
        let page = ~~(total / lazyParams.rows)
        setLazyParams({...lazyParams, first: page * lazyParams.rows, page: page})
        setSelectedUser({...defaultValues})
    }

    const actionBodyTemplate = (rowData)=>{
        return (<div>
            <ClearButtonOutlined
                style={{width:"20px",height:"20px"}}
                className="ml-4 p-button-text"
                type="button"
                onClick = {()=>{
                    context.apiService.deleteUser(rowData)
                    setItems(items.filter(i=>i.id!==rowData.id))
                }}
            />
        </div>)
    }

    const formatDate = (value) => {
        return value.toLocaleDateString('ru-RU', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        })
    };
    const dateBodyTemplate = (rowData) => {
        if (rowData.regDate === null) return null
        return formatDate(parseISO(rowData.regDate));
    };

    const onChangePage = (e)=>{
        setLazyParams({...lazyParams, first:e.first, rows:e.rows, page: e.page })
    }

    return (
        <>
            <Toast ref={toast} position="top-center"/>
            <SplitPane
                split="vertical" primary="second"
                defaultSize={selectedUser ? "50%" : "0"}
                style={{position:"relative"}}
            >
            {/*<Splitter>*/}
                {/*<SplitterPanel size={70}>*/}
                <div>
                    <Panel
                        className="m-2"
                        headerTemplate={usersHeader}
                    >
                        <SearchInput
                            value={lazyParams.login || ""}
                            onChange={(e)=>{setLazyParams({...lazyParams, page:0, login:e.target.value})}}
                        />
                        <BlockUI blocking={isLoading}>
                        <DataTable
                            ref={dataTable}
                            value={items} lazy
                            selectionMode="single"
                            selection={selectedUser}
                            paginator
                            first={lazyParams.first}
                            rows={lazyParams.rows}
                            totalRecords={total}
                            onSelectionChange={e => {
                                if (e.value)
                                    setSelectedUser(e.value)
                            }}
                            onPage={onChangePage}
                            paginatorLeft={()=>(<Button
                                icon="pi pi-plus"
                                rounded
                                onClick={createUser}
                            />)}
                        >
                            <Column field="login" header = "login"/>
                            <Column field="person.surname" header = "Фамилия"/>
                            <Column field="person.name" header = "Имя"/>
                            <Column field="person.patronymic" header = "Отчество"/>
                            <Column dataType="date" body={dateBodyTemplate} header = "Дата регистрации"/>
                            <Column header="Вход разрешен" body={(row)=>(<>{row.approved && <span className="pi pi-check"/>}</>)}  field="approved" />
                            {!usersLocked && <Column body={actionBodyTemplate} style={{width:"5rem"}}/> }
                        </DataTable>
                        </BlockUI>
                    </Panel>
                </div>
                <div>
                    <User user={selectedUser} onSaveUser={saveUser} style={{width:"100%"}}/>
                </div>
            </SplitPane>
        </>
    );
}

export default observer(Users);
