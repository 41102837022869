import React, {useEffect, useState} from 'react'
import {useMatchMedia} from "../../lib/useMatch";
import ReadOnlyRow from "./ReadOnlyRow";
import EditableRow from "./EditableRow";
import ReadOnlyCard from "./ReadOnlyCard";
import EditableCard from "./EditableCard";

function ResponsiveGrid(props) {

    const {onRowEditComplete, onRowEditChange, onRowDelete, emptyMessage} = props;

    const {isMobile} = useMatchMedia();
    const [columns, setColumns] = useState([]);
    const [editingRows, setEditingRows] = useState([]);

    const makeColumn = (columnDescription) => {
        return columnDescription.props;
    }

    useEffect(() => {
        let _columns = [];
        if (props.children) {
            if (Array.isArray(props.children)) {
                props.children.forEach(column => {
                    if (column) {
                        let _c = makeColumn(column);
                        _columns.push(_c)
                    }
                })
            } else {
                _columns.push(makeColumn(props.children))
            }
        }
        setColumns(_columns);
    }, [props.children]);

    const onRowEdit = (data) => {
        const value = data.data
        if (!editingRows.includes(value)) {
            setEditingRows([...editingRows, value]);
        }
        if(onRowEditChange) onRowEditChange(data);
    }

    const onCancelEdit = (data) => {
        console.log(data)
        setEditingRows(editingRows.filter(v => v !== data.data));
        if(onRowEditChange) onRowEditChange(data)
    }

    const onRowSave = (data) => {
        setEditingRows(editingRows.filter(v => v !== values[data.index]));
        if (onRowEditComplete) onRowEditComplete(data)
        if (onRowEditChange) onRowEditChange({index: data.index, data: data.newData})
    }

    const onRowDeleteEvent = (data) => {
        if (onRowDelete) onRowDelete(data)
    }

    const createTableHeader = (columns) => {
        return (
            <thead className={"p-datatable-thead"}>
            <tr>
                {columns.map((column, index) => {
                    return (
                        <th style={column?.headerStyle} key={index}>
                            <div className={"p-column-header-content"}>{column?.header}</div>
                        </th>
                    )
                })}
            </tr>
            </thead>
        )
    }

    const createRows = (values) => {
        if (values.length === 0) return (
            <tr>
                <td colSpan={columns.length}>{emptyMessage ? emptyMessage: "No data to display"}</td>
            </tr>
        )
        return values.map((value, index) => {
            if (editingRows.includes(value)) return (
                <EditableRow
                    columns={columns}
                    value={value}
                    key={index}
                    rowIndex={index}
                    onCancel={onCancelEdit}
                    onSave={onRowSave}
                />
            )
            return (
                <ReadOnlyRow
                    columns={columns}
                    value={value}
                    key={index}
                    rowIndex={index}
                    onRowEdit={onRowEdit}
                    onRowDelete={onRowDeleteEvent}
                />
            )
        })
    }

    const createTableBody = (values) => {
        return (
            <tbody className={"p-datatable-tbody"}>
            {createRows(values)}
            </tbody>
        )
    }

    const createGridView = (values) => {
        if (columns.length <= 0) return <></>
        return (
            <table className={"p-datatable p-datatable-table"}>
                {createTableHeader(columns)}
                {createTableBody(values)}
            </table>
        )
    }

    const createCard = (value, columns, rowIndex) => {
        if (editingRows.includes(value)) return (
            <EditableCard
                key={rowIndex} columns={columns} value={value} rowIndex={rowIndex}
                onCancel={onCancelEdit}
                onSave={onRowSave}
            />
        )
        return (
            <ReadOnlyCard
                key={rowIndex} columns={columns} value={value} rowIndex={rowIndex}
                onRowDelete={onRowDeleteEvent}
                onRowEdit={onRowEdit}
            />
        )
    }

    const createCardView = (values) => {
        return (
            <div>
                {values.map((value, index) => {
                    return createCard(value, columns, index)
                })}
            </div>
        )
    }

    const {values} = props

    if (isMobile) {
        return createCardView(values)
    }
    return (
        createGridView(values)
    )
}

export default ResponsiveGrid
