import React from 'react';
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";

function NumberEditor(props) {
    const {className, ...rest} = props
    return (
        <div>
            <NumberFormat className={"p-inputtext p-component "+className} {...rest}/>
        </div>
    );
}

NumberEditor.propTypes = {
    thousandSeparator: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    decimalSeparator: PropTypes.string,
    thousandsGroupStyle: PropTypes.oneOf(['thousand', 'lakh', 'wan']),
    decimalScale: PropTypes.number,
    fixedDecimalScale: PropTypes.bool,
    displayType: PropTypes.oneOf(['input', 'text']),
    prefix: PropTypes.string,
    suffix: PropTypes.string,
    format: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    removeFormatting: PropTypes.func,
    mask: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isNumericString: PropTypes.bool,
    customInput: PropTypes.object,
    allowNegative: PropTypes.bool,
    allowEmptyFormatting: PropTypes.bool,
    allowLeadingZeros: PropTypes.bool,
    onValueChange: PropTypes.func,
    type: PropTypes.oneOf(['text', 'tel', 'password']),
    isAllowed: PropTypes.func,
    renderText: PropTypes.func,
    getInputRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.any })
    ]),
    allowedDecimalSeparators: PropTypes.arrayOf(PropTypes.string),
    customNumerals: PropTypes.arrayOf(PropTypes.string),
    onKeyDown: PropTypes.func,
    onMouseUp: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool
}

export default NumberEditor;
