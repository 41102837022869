import styled from "styled-components";

export const CenteredLabelStyled = styled.div`
  display: flex;
  align-items: center; 
  font-weight: bold;
`
export const CenteredItemStyled = styled.div`
  display: flex;
  align-items: center;
`
export const FullCenteredItemStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`