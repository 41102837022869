import React, {useContext} from 'react';
import NameFilter from "../../../shared/ui/UniversalList/NameFilter";
import UniversalList from "../../../shared/ui/UniversalList";
import {AppContext} from "../../../app/AppContext";
import PropTypes from "prop-types";

function OrgsList(props) {

    const context = useContext(AppContext)
    const itemsService = context.apiService

    return (
        <div>
            <UniversalList
                header="Организации"
                visible={props.visible}
                //filtersTemplate={filterTemplate}
                filtersTemplate={NameFilter}
                //rowViewerTemplate={rowViewTemplate}
                //rowEditorTemplate={rowEditorTemplate}
                onHide={props.onHide}
                onGetData={itemsService.getOrs.bind(itemsService)}
                onSelectItem={props.onSelectItem}
                onEditRow={(row)=>{console.log(row)}}
                onDeleteRow={(row)=>{console.log(row)}}
                onAddRow={(row)=>{console.log(row)}}
            />
        </div>
    );
}

OrgsList.propTypes = {
    onSelectItem: PropTypes.func.isRequired,
    onHide: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired
}

export default OrgsList;
