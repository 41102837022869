import styled from "styled-components";
import {ScaleLoader} from "react-spinners";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";

const BlockUIStyled = styled.div.attrs(props=>({
    className: props.blocking ? "blocked" : ""
}))`
    &.blocked{
        position: relative;
        opacity: 0.4;
        pointer-events: none;
    }
`

const ScaleLoaderStyled = styled(ScaleLoader).attrs(props=>({
  color:"rgba(20,154,224,0.87)"
}))`
    z-index: 1000;
    top: 30%;
    left: 50%;
    position: absolute;
`

const BlockUI = (props) => {
    const [show, setShow] = useState(false)
    useEffect(() => {
        let isMounted = true
        setShow(false);
        setTimeout(
            ()=>{
                if (isMounted) setShow(props.blocking)
            },
            props.blockingTimeout? props.blockingTimeout : 1000
        )
        return ()=>{isMounted=false}
    }, [props.blocking, props.blockingTimeout]);
    return <>
        <BlockUIStyled blocking={props.blocking && show}>
            <ScaleLoaderStyled loading={props.blocking && show}/>
            {props.children}
        </BlockUIStyled>
    </>
}

BlockUI.propTypes = {
    blocking: PropTypes.bool,
    blockingTimeout: PropTypes.number
}

export {BlockUI}