import { types } from "mobx-state-tree"
import {Ath} from "../../Ath/model/Ath";
import {RpLecform} from "../../LecForm/model/RpLecform";
import {RpType} from "../../RpType/model/RpType";
import {Person} from "../../Person/model/Person";
import {PrescriptionItem} from "../../PrescriptionItem/model/PrescriptionItem";
import {Area} from "../../Area/model/Area";
import {Mkb10} from "../../Mkb10/model/Mkb10";

export const Rp = types.model({
    id: types.maybe(types.integer),
    name: "",
    mds: "",
    action: "",
    recipe: types.maybeNull(types.string),
    ath: types.array(Ath),
    approved: types.maybeNull(types.boolean),
    lecForm: types.maybeNull(RpLecform),
    type: types.array(RpType),
    mkb10: types.array(Mkb10),
    author: types.maybeNull(Person),
    items: types.array(PrescriptionItem),
    price: types.maybeNull(types.number),
    areas: types.array(Area)
})
