import React from "react";
import {SelectionButtonOutlined, StyledChip} from "../Styles";
import PropTypes from "prop-types";

function ChipsList(props){
    const readOnly = props.readOnly
    const values = props.values
    const field = props.field ? props.field : "name"

    const onRemoveItem = props.onRemoveItem
    const onAddItem = props.onAddItem

    const renderChips = (values) =>{
        if (values) return <span> {values.map((e,i)=>
            <StyledChip
                key={e.id}
                label={e[field]}
                removable={!readOnly}
                onRemove={()=>onRemoveItem(e)}
            />
        )}</span>
        return <></>
    }

    return(
        <div>
            {renderChips(values)}
            {!readOnly && <span style={{display:"inline-block",verticalAlign:"middle"}} className="ml-2">
                <SelectionButtonOutlined
                    type="button"
                    onClick={()=>{onAddItem()}}
                />
            </span>}
        </div>
    )
}

ChipsList.prototypes = {
    readOnly: PropTypes.bool,
    values: PropTypes.array,
    onRemoveItem: PropTypes.func,
    onAddItem: PropTypes.func
}

export default ChipsList