import React, {useContext, useEffect, useState} from 'react';
import {Controller, useForm, useFormState} from "react-hook-form";
import {InputText} from "primereact/inputtext";
import {classNames} from "primereact/utils";
import {Panel} from "primereact/panel";
import {Button} from "primereact/button";
import ItemsList from "../../../../shared/ui/ItemsList";
import {AppContext} from "../../../../app/AppContext";
import {InputTextarea} from "primereact/inputtextarea";
import ListItemEditor from "../../../../shared/ui/ListItemEditor";
import PropTypes from "prop-types";
import RpItemsEditor from "./RpItemsEditor";
import EmptyItem from "../../../../shared/ui/EmptyItem";
import NumberEditor from "../../../../shared/ui/NumberEditor";
import AreasList from "../../../Area/ui/AreasList";
import ChipsList from "../../../../shared/ui/ChipsList";
import Mkb10List from "../../../Mkb10/ui/Mkb10List/Mkb10List";

function Rp(props) {
    const {rp, onSaveRp, setIsDirty, readOnly, showRecipe} = props
    const {control, handleSubmit, reset, watch, formState: {errors}, setValue, getValues} = useForm({defaultValues: undefined});

    const {isDirty} = useFormState({control})

    const [displayLecFormList, setDisplayLecFormList] = useState(false);
    const [displayRpTypeList, setDisplayRpTypeList] = useState(false);
    const [displayATHList, setDisplayATHList] = useState(false);
    const [displayMKBList, setDisplayMKBList] = useState(false);
    const [showAreasDialog, setShowAreasDialog] = useState(false);

    const context = useContext(AppContext)
    const itemsService = context.apiService

    const grid = {display: "grid", gridTemplateColumns: "150px 1fr", gridRowGap: "0.5rem"}
    const centeredLabel = {display: "flex", alignItems: "center", fontWeight: "bold"}

    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>
    };

    const onChangePrice = (e) =>{
        if (typeof e.value === 'string' || e.value instanceof String) {
            setValue("price", Number(e.value), {shouldDirty: true})
        } else
            setValue("price", e.value, {shouldDirty: true})
    }

    const onAddArea = (data)=>{
        let a = getValues().areas
        if (!a.find(i=>i.id===data.id)){
            a.push(data)
            setValue("areas", a, {shouldDirty:true})
        }
        setShowAreasDialog(false)
    }

    const onRemoveArea = (e) =>{
        let a = getValues().areas.filter((i)=>{return i.id !== e.id})
        setValue("areas", a, {shouldDirty:true})
    }

    const onAddType = (data)=>{
        let a = getValues().type
        if (!a.find(i=>i.id===data.id)){
            a.push(data)
            setValue("type", a, {shouldDirty:true})
        }
        setDisplayRpTypeList(false)
    }
    const onRemoveType = (e) =>{
        let a = getValues().type.filter((i)=>{return i.id !== e.id})
        setValue("type", a, {shouldDirty:true})
    }

    const onAddAth = (data)=>{
        let a = getValues().ath
        if (!a.find(i=>i.id===data.id)){
            a.push(data)
            setValue("ath", a, {shouldDirty:true})
        }
        setDisplayATHList(false)

    }
    const onRemoveAth = (e) =>{
        let a = getValues().ath.filter((i)=>{return i.id !== e.id})
        setValue("ath", a, {shouldDirty:true})
    }

    const onAddMKB = (data)=>{
        let a = getValues().mkb10
        if (!a.find(i=>i.id===data.id)){
            a.push(data)
            setValue("mkb10", a, {shouldDirty:true})
        }
        setDisplayMKBList(false)

    }
    const onRemoveMKB = (e) =>{
        let a = getValues().mkb10.filter((i)=>{return i.id !== e.id})
        setValue("mkb10", a, {shouldDirty:true})
    }

    useEffect(() => {
        if (setIsDirty)
            setIsDirty(isDirty)
    }, [setIsDirty, isDirty])

    useEffect(() => {
        let isMounted = true;
        if (rp && isMounted) {
            reset({
                ...rp,
                recipe: rp.recipe ? rp.recipe : ""
            })
        }
        return () => {
            isMounted = false
        }
    }, [reset, rp]);

    if (!rp) return (
        <div>
            <EmptyItem label="Выберите пропись для просмотра" height={"400px"}/>
        </div>
    )

    return (
        <div>

            <AreasList
                visible={showAreasDialog}
                onHide={()=>{setShowAreasDialog(false)}}
                onSelectItem={onAddArea}
            />

            <ItemsList
                header="Анатомо-терапевтическая химическая классификация"
                visible={displayATHList}
                onHide={() => {setDisplayATHList(false)}}
                getData={itemsService.getAth.bind(itemsService)}
                onAccept={onAddAth}
            />

            <Mkb10List
                visible={displayMKBList}
                onHide={() => {setDisplayMKBList(false)}}
                onSelectItem={onAddMKB}
            />

            <ItemsList
                header="Формы выпуска"
                visible={displayLecFormList}
                onHide={() => setDisplayLecFormList(false)}
                getData={itemsService.getRpLecForms.bind(itemsService)}
                onAccept={(e) => {
                    setValue("lecForm", e, {shouldDirty: true})
                    setDisplayLecFormList(false)
                }}
            />

            <ItemsList
                header="Тип"
                visible={displayRpTypeList}
                onHide={() => setDisplayRpTypeList(false)}
                getData={itemsService.getRpTypeList.bind(itemsService)}
                onAccept={onAddType}
            />

            <form onSubmit={handleSubmit(onSaveRp)}>
                {rp && <Panel>

                    <div style={grid}>
                        <div style={centeredLabel}>
                            Наименование :
                        </div>
                        <div>
                            <Controller
                                name="name"
                                control={control}
                                rules={{required: 'Нименование обязателено к заполнению'}}
                                render={({field, fieldState}) => (
                                    <InputText
                                        value={field.value || ""}
                                        onChange={field.onChange}
                                        autoComplete="off"
                                        className={classNames({'p-invalid': fieldState.invalid})}
                                        style={{width: "100%"}}
                                        required={true}
                                        readOnly={readOnly}
                                    />)
                                }
                            />
                            {getFormErrorMessage("name")}
                        </div>

                        <div style={centeredLabel}>
                            Область :
                        </div>
                        <div><Controller
                            name="areas"
                            control={control}
                            render={({field}) => (
                                <ChipsList
                                    readOnly={readOnly}
                                    values={field.value}
                                    onRemoveItem={onRemoveArea}
                                    onAddItem={() => setShowAreasDialog(true)}
                                />
                            )}
                        /></div>

                        <div style={centeredLabel}>
                            Тип действия :
                        </div>
                        <div><Controller
                            name="type"
                            control={control}
                            render={({field}) => (
                                <ChipsList
                                    readOnly={readOnly}
                                    values={field.value}
                                    onRemoveItem={onRemoveType}
                                    onAddItem={() => setDisplayRpTypeList(true)}
                                />
                            )}
                        /></div>

                        <div style={centeredLabel}>
                            ATX :
                        </div>
                        <div><Controller
                            name="ath"
                            control={control}
                            render={({field}) => (
                                <ChipsList
                                    readOnly={readOnly}
                                    values={field.value}
                                    onRemoveItem={onRemoveAth}
                                    onAddItem={() => setDisplayATHList(true)}
                                />
                            )}
                        /></div>

                        <div style={centeredLabel}>
                            МКБ10 :
                        </div>
                        <div><Controller
                            name="mkb10"
                            control={control}
                            render={({field}) => {
                                return (
                                    <ChipsList
                                        readOnly={readOnly}
                                        field="code"
                                        values={field.value}
                                        onRemoveItem={onRemoveMKB}
                                        onAddItem={() => setDisplayMKBList(true)}
                                    />
                                )
                            }}
                        /></div>

                        <div style={centeredLabel}>
                            Форма выпуска
                        </div>
                        <div>
                            <Controller
                                name="lecForm"
                                control={control}
                                render={({field}) => (
                                    <ListItemEditor
                                        value={field.value?.name}
                                        onSelect={() => {
                                            setDisplayLecFormList(true)
                                        }}
                                        onClear={() => {
                                            setValue("lecForm", undefined, {shouldDirty: true})
                                        }}
                                        readOnly={readOnly}
                                    />
                                )}
                            />
                        </div>

                        <div style={centeredLabel}>
                            Rp. :
                        </div>

                        <RpItemsEditor
                            style={{width: "100%"}}
                            control={control}
                            watch={watch}
                            setValue={setValue}
                            readOnly={readOnly}
                        />

                    </div>
                    <div style={centeredLabel}>
                        M.D.S. :
                    </div>
                    <div>
                        <Controller
                            name="mds"
                            control={control}
                            rules={{required: 'M.D.S обязателено к заполнению'}}
                            render={({field, fieldState}) => {
                                // <QuillSimpleEditor
                                // value={realState}
                                // onChange={e => setRealState(e)}
                                return <InputTextarea
                                    //{...field}
                                    value={field.value || ""}
                                    onChange={field.onChange}
                                    modules={{toolbar: false}}
                                    autoComplete="off"
                                    className={classNames({'p-invalid': fieldState.invalid})}
                                    style={{width: "100%"}}
                                    required={true}
                                    autoResize
                                    readOnly={readOnly}
                                />

                            }
                            }
                        />
                        {getFormErrorMessage("mds")}
                    </div>
                    <div style={centeredLabel}>
                        Действие и показания:
                    </div>
                    <div>
                        <Controller
                            name="action"
                            control={control}
                            rules={{required: 'Действие и показания обязателено к заполнению'}}
                            render={({field, fieldState}) => (
                                //<QuillSimpleEditor
                                <InputTextarea
                                    //{...field}
                                    value={field.value || ""}
                                    onChange={field.onChange}
                                    autoComplete="off"
                                    className={classNames({'p-invalid': fieldState.invalid})}
                                    style={{width: "100%"}}
                                    autoResize
                                    readOnly={readOnly}
                                />)
                            }
                        />
                        {getFormErrorMessage("action")}
                    </div>

                    {showRecipe && <div style={centeredLabel}>
                        Способ приготовления:
                    </div>}
                    {showRecipe && <div>
                        <Controller
                            name="recipe"
                            control={control}
                            render={({field, fieldState}) => (
                                <InputTextarea
                                    {...field}
                                    autoComplete="off"
                                    className={classNames({'p-invalid': fieldState.invalid})}
                                    style={{width: "100%"}}
                                    autoResize
                                    readOnly={readOnly}
                                />)
                            }
                        />
                        {getFormErrorMessage("recipe")}
                    </div>}

                    <div style={centeredLabel}>
                       Цена
                    </div>
                    <div>
                        <Controller
                            name="price"
                            control={control}
                            render={({field, fieldState}) =>(
                                <NumberEditor
                                    value={field.value}
                                    onValueChange={onChangePrice}
                                    // value={prescription.price || ""}
                                    allowedDecimalSeparators={[".", ","]}
                                    // onValueChange={onChangePrice}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    fixedDecimalScale={true}
                                    suffix=" p"
                                    // readOnly={prescription.finished}
                                />
                            )}
                        />
                    </div>

                    {isDirty && <div className="mt-1">
                        <Button
                            label="Сохранить изменения"
                            className="p-d-block mx-auto"
                            type="submit"
                        />
                    </div>}
                </Panel>}

            </form>

        </div>
    );
}

Rp.propTypes = {
    rp: PropTypes.object,
    onSaveRp: PropTypes.func,
    setIsDirty: PropTypes.func,
    readOnly: PropTypes.bool
}

export default Rp;
