import { types } from "mobx-state-tree"
import {Person} from "../../Person/model/Person";
import {MedicalOrg} from "../../MedicalOrg/model/MedicalOrg";

export const PatientCore = types.model({
    person: Person,
    medicalOrg: types.maybeNull(MedicalOrg),
    doctors: types.array(Person)
}).volatile(self => ({
    _isDirty: false
})).actions(self => ({
    setMedicalOrg(medicalOrg){
        self.medicalOrg =  medicalOrg
        self._isDirty = true
    },
    addDoctor(doctor){
        self.doctors.push(doctor)
        self._isDirty = true
    },
    removeDoctor(doctor){
        self.doctors = self.doctors.filter(i=>(i.id !== doctor.id))
        self._isDirty = true
    },
    clearIsDirty(){
        self._isDirty = false;
        self.person.clearIsDirty()
    }
})).views(self=>({
    get fullName(){
        return `${self.person.surname} ${self.person.name} ${self.person.patronymic} `
    },
    get isDirty(){
        return self._isDirty || self.person.isDirty
    }
}))

export const Patient = types.compose(
    types.model({
        id: types.identifierNumber,
    }), PatientCore
)

export const PatientEditable = types.compose(
    types.model({
        id: types.maybeNull(types.number),
    }), PatientCore
)

// export const PatientApiWrapper = types.snapshotProcessor(Patient, {
//     preProcessor(snapshot) {
//         return {...snapshot,
//             doctors: snapshot.doctors = null ? [] : snapshot.doctors,
//             person:{...snapshot.person,
//                 birthDate:parseISO(snapshot.person.birthDate)
//             }
//         }
//     },
//     postProcessor(snapshot) {
//         return {...snapshot, person:{...snapshot.person, birthDate:formatISO(snapshot.person.birthDate)}}
//     }
// })
