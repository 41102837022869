import React from 'react';
import {ClearButtonOutlined, SelectionButtonOutlined} from "./Styles";
import PropTypes from "prop-types";

function ListItemEditor(props) {
    const {value, onSelect, onClear, readOnly} = props
    return (
        <div>
            <span style={{display:"inline-block",verticalAlign:"middle"}}>{value}</span>
            {!readOnly && <span style={{display:"inline-block",verticalAlign:"middle"}} className="ml-2">
                <SelectionButtonOutlined
                    type="button"
                    onClick={onSelect}
                />
            </span>}
            {!readOnly && onClear && <span style={{display:"inline-block",verticalAlign:"middle"}} className="ml-2">
                <ClearButtonOutlined
                    type="button"
                    onClick={onClear}
                />
            </span>}
        </div>
    );
}

ListItemEditor.propTypes = {
    name: PropTypes.string,
    value: PropTypes.any,
    onSelect: PropTypes.func,
    onClear: PropTypes.func,
    readOnly: PropTypes.bool,
    border: PropTypes.bool
}
export default ListItemEditor;
