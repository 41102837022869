import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from "react-redux";
import './index.scss';
import App from './app/App';
import {BrowserRouter as Router} from "react-router-dom";
import {store} from "./app/model/AppState";

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Router>
                <App/>
            </Router>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

export {CenteredItemStyled} from "./shared/ui/CenteredItems.styled";
export {FullCenteredItemStyled} from "./shared/ui/CenteredItems.styled";