import React, {useEffect, useState} from "react";
import "primeflex/primeflex.css";
import {observer} from "mobx-react-lite";
import {Paginator} from "primereact/paginator";
import {Panel} from "primereact/panel";
import LabeledSearchInput from "../../../../shared/ui/LabeledSearchInput";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {getSnapshot} from "mobx-state-tree";
import {BlockUI} from "../../../../shared/ui/BlockUI";
import {useHistory} from "react-router-dom";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import PersonEditor from "../../../../entyties/Patient/ui/PatientList/PersonEditor";

const Patients = (props) => {

    const store = props.store;
    const history = useHistory()
    const [params, setParams] = useState({
        first: 0,
        rows:10,
        page:0
    });
    const [showPatientList, setShowPatientEditor] = useState(false)
    // const [filters, setFilters] = useState({
    //     "person.name": { value: null, matchMode: FilterMatchMode.CONTAINS },
    //     "person.surname": { value: null, matchMode: FilterMatchMode.CONTAINS },
    //     "person.patronymic": { value: null, matchMode: FilterMatchMode.CONTAINS },
    // });

    useEffect(() => {
        store.fetchPatients({...params, doctor_id:store.doctorId})
    }, [params, store, store.doctorId]);

    const onChangePage = (e)=>{
        setParams({...params, first:e.first, rows:e.rows, page: e.page })
    }

    // const ifNull = (value, def) =>{
    //     return value ? value : def
    // }

    // const onFilter = (e) =>{
    //     setFilters(e.filters)
    //     setParams({
    //         ...params,
    //         name:ifNull(e.filters.person?.name.value,""),
    //         surname:ifNull(e.filters.person?.surname.value,""),
    //         patronymic:ifNull(e.filters.person?.patronymic.value,""),
    //         page: 0
    //     })
    // }

    const AddPatientDialog = (props) =>(
        <Dialog
            header={"Пациент"}
            visible={props.visible}
            onHide={props.onHide}
        >
            <PersonEditor onFinished={
                (data)=>{
                    if (data){
                        store.addPatient(data)
                        setShowPatientEditor(false)
                    }
                }
            }/>
        </Dialog>
    )
    //if (store.isLoading) return (<></>)
    return (
        <div className={"m-3"}>

            <AddPatientDialog visible = {showPatientList} onHide={()=>setShowPatientEditor(false)} />
            <Panel>
                <LabeledSearchInput
                    value={params.surname}
                    label="Фамилия"
                    onChange = {(e)=>{
                        setParams({...params, surname:e.target.value, page:0})
                    }}
                />
                <LabeledSearchInput
                    value={params.name}
                    label="Имя"
                    onChange = {(e)=>{
                        setParams({...params, name:e.target.value, page:0})
                    }}
                />
                <LabeledSearchInput
                    value={params.patronymic}
                    label="Отчество"
                    onChange = {(e)=>{
                        setParams({...params, patronymic:e.target.value, page:0})
                    }}
                />
            </Panel>

            <BlockUI blocking={store.isLoading} blockingTimeout={10}>
                <DataTable
                    value={store.patients}
                    className={"mt-2"}
                    selectionMode="single"
                    emptyMessage={"Пациентов еще нет"}
                    selection={store.patient}
                    onSelectionChange={(e) => {
                        if (e.value)
                            store.setPatient(getSnapshot(e.value))
                    }}
                    onRowClick={(e) => {
                        history.push(`doctor/patient/${e.data.id}`)
                    }}
                >
                    <Column field={"person.surname"} header={"Фамилия"}/>
                    <Column field={"person.name"} header={"Имя"}/>
                    <Column field={"person.patronymic"} header={"Отчество"}/>
                </DataTable>
            </BlockUI>
            <Paginator
                rows={params.rows}
                first={params.first}
                totalRecords={store.totalPatients}
                onPageChange={onChangePage}
                leftContent={() => (
                    <Button icon="pi pi-plus" rounded
                            onClick={()=> setShowPatientEditor(true)}
                    />
                )}
            />
        </div>
    )
}
export default observer(Patients)