import React, {useState} from 'react';
import {InputText} from "primereact/inputtext";
import {Panel} from "primereact/panel";
import {Button} from "primereact/button";

function OneRowItemEditor(props) {
    const {onSave, onCancel} = props
    const grid = {display:"grid", gridTemplateColumns:"150px 1fr", gridRowGap:"0.5rem"}
    const [name, setName] = useState("");

    const onSaveButtonClicked = () =>{
        onSave(name)
    }

    return (
        <form className="mt-1" >
            <div style={grid}>
                <div>Наименование</div>
                <InputText
                    value={name}
                    style={{width:"100%"}}
                    autoFocus={true}
                    onChange={(e)=>{
                        setName(e.target.value)
                    }}/>
            </div>
            <Panel className="mt-1">
                <div style={{textAlign:"center"}}>
                    <Button
                        type="button"
                        label = "Сохранить изменения"
                        onClick={onSaveButtonClicked}
                    />
                    <Button
                        type="button"
                        label = "Отмена"
                        className="ml-1"
                        onClick={onCancel}
                    />
                </div>
            </Panel>
        </form>
    );
}

export default OneRowItemEditor;