import React, {useContext, useEffect, useRef, useState} from 'react'
import {useHistory, useParams} from "react-router-dom";
import {PasswordCard, Row} from "../../../shared/ui/Styles";
import logo from "../../../shared/assets/logo4.png";
import PasswordEditor from "../../../shared/ui/PasswordEditor";
import {Button} from "primereact/button";
import {Toast} from "primereact/toast";
import {AppContext} from "../../../app/AppContext";
import {BlockUI} from "../../../shared/ui/BlockUI";

function ResetPassword(props) {
    const { token } = useParams();
    const context = useContext(AppContext)
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    const [isValidLink, setIsValidLink] = useState(false)
    const [userName, setUserName] = useState(false)
    const [password1, setPassword1] = useState("")
    const [password2, setPassword2] = useState("")
    const toast = useRef()
    const [confirmPassword, setConfirmPassword] = useState(true)

    const onSubmit = (e) =>{
        e.preventDefault();

        if (confirmPassword && password1 !== password2)  {
            toast.current.show({severity: 'error', summary: 'Внимание', detail: "Пароли не совпадают", life: 3000});
            return
        }

        if (context.apiService.checkPasswordStrength(password1)<3){
            toast.current.show({severity: 'error', summary: 'Внимание', detail: "Пароль слишком простой", life: 3000});
            return
        }

        context.apiService.resetPassword(token, password1).then(
            (data)=>{
                if (data) {
                    history.push("/")
                } else
                    toast.current.show({severity: 'error', summary: 'Внимание', detail: "Не удалось сменить палоль", life: 3000});
            }
        )
    }

    const checkResetLink = () => {
        context.apiService.checkResetLink(token).then(data => {
            if (data) {
                setIsValidLink(true)
                setUserName(data.userName)
            } else setIsValidLink(false)
            setIsLoading(false)
        }).catch(()=>{
            console.log("Link not valid")
        })
    }

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            setIsLoading(true)
            checkResetLink()
        }
        return () => {
            isMounted = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <PasswordCard style={{width:"260px", height:"500px", margin:"auto"}}>
            <BlockUI blocking={isLoading}>
                <Row>
                    <img src={logo}  alt={"Logo"} style={{width:"100%"}}/>
                </Row>
                {!isValidLink && <div>
                    <Row className="flex justify-content-center flex-wrap">
                        <p className="text-lg text-center w-10">Ссылка на востановление пароля устарела</p>
                    </Row>
                    <Row className="mt-2">
                        <Button type="button" style={{width:"100%"}} label="Запросить повторно"
                                onClick={()=>{history.push("/restore_pass")}}
                        />
                    </Row>
                </div>}
                {isValidLink && <form onSubmit={onSubmit}>

                    <Toast ref={toast} position="top-center"/>

                    <Row className="mt-2">
                        <p className="text-base w-10">Установка нового пароля для пользователя: <b>{userName}</b> </p>
                    </Row>

                    <Row className="mt-2">
                        <p className="text-sm ">Пароль должен содержать цифры и буквы, и его длина должна быть более 6 символов.</p>
                    </Row>

                    <Row className="mt-2">
                        <PasswordEditor
                            value={password1}
                            placeholder="Новый пароль"
                            toggleMask
                            onChange={(e)=>{setPassword1(e.target.value)}}
                            onToggleMask={(state)=>{setConfirmPassword(state)}}
                        />
                    </Row>

                    {confirmPassword && <Row className="mt-2">
                        <PasswordEditor
                            value={password2}
                            placeholder="Подтвердите пароль"
                            onChange={(e)=>{setPassword2(e.target.value)}}
                        />
                    </Row>}

                    <Row className="mt-2">
                        <Button type="submit" style={{width:"100%"}} severity={"success"} label="Установить новый пароль"/>
                    </Row>

                </form>}
            </BlockUI>
        </PasswordCard>
    )
}

export default ResetPassword
