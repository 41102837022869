import { types } from "mobx-state-tree"
import {Abbreviation} from "../../Abbreviation/model/Abbreviation";
import {QuantType} from "../../QuantType/model/QuantType";
import {Component} from "../../Component/model/Component";

export const PrescriptionItem = types.model({
    id: types.maybeNull(types.integer),
    component: types.maybe(Component),
    concentration: types.maybeNull(types.number),
    quant: types.maybeNull(types.number),
    quantType: types.maybeNull(QuantType),
    volume: types.maybeNull(types.number),
    serialNumber: types.maybeNull(types.integer),
    abbreviation: types.maybeNull(Abbreviation),
}).actions(self => ({
    setId(id){
        self.id = id
    },
    setConcentration(concentration){
        self.concentration = concentration
    },
    setQuant(quant){
        self.quant = quant
    },
    setQuantType(quantType){
        self.quantType = quantType
    },
    setVolume(volume){
        self.volume = volume
    },
    setSerialNumber(serialNumber){
        self.serialNumber = serialNumber
    },
    setAbbreviation(abbreviation){
        self.abbreviation = abbreviation
        self.isDirty = true;
    },
    update(data){
        self.concentration = data.concentration ? Number(data.concentration) : null
        self.quant = data.quant ? Number(data.quant) : null
        self.quantType = data.quantType
        self.volume = data.volume ? Number(data.volume) : null
        self.serialNumber = data.serialNumber
        self.abbreviation = data.abbreviation
        self.isDirty = true
    },
    setIsDirty(value){
        self.isDirty = value;
    }
})).volatile(self=>({
    isDirty: false
}))
