import React from 'react';
import {Toolbar} from "primereact/toolbar";
import styled from "styled-components";
// import {Button} from "primereact/button";
import UserMenu from "../../../entyties/User/ui/UserMenu";
// import {useHistory, useLocation} from "react-router-dom";

const TopToolbar = styled(Toolbar)`
  position: fixed;
  top: 0; 
  width: 100%; 
  z-index: 1;
`

function ToolBar(props) {
    // const history = useHistory()

    const leftContents = (
        <>
        </>
    )
    const rightContents = (
        <>
            {
                <>
                    <UserMenu />
                </>
            }
        </>
    )
    return (
        <TopToolbar
            left={leftContents}
            right={rightContents}
        />
    );
}

export default ToolBar;
