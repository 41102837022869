import React from 'react';
import {InputText} from "primereact/inputtext";

function PersonFilter(props) {
    const {params, setParams} = props
    return (
        <div className="p-input-icon-left p-d-block" >
            <span className="p-input-icon-left mr-2 mt-4 p-float-label">
                <i className="pi pi-search" />
                <InputText value={params.surname || ""} className=""
                   onChange ={(e) => {setParams({...params, surname:e.target.value})}}
                           autoFocus/>
                <label>Фамилия</label>
            </span>
            <span className="p-input-icon-left mr-2 p-float-label">
                <i className="pi pi-search" />
                <InputText value={params.name || ""} className=""
                           onChange ={(e) => {setParams({...params, name:e.target.value})}}
                />
                <label>Имя</label>
            </span>
            <span className="p-input-icon-left mr-2 p-float-label">
                <i className="pi pi-search" />
                <InputText value={params.patronymic || ""} className=""
                           onChange ={(e) => {setParams({...params, patronymic:e.target.value})}}
                />
                <label>Отчество</label>
            </span>
            <span className="p-input-icon-left p-float-label">
                <i className="pi pi-search" />
                <InputText value={params.snils || ""} className=""
                           onChange ={(e) => {setParams({...params, snils:e.target.value})}}
                />
                <label>СНИЛС</label>
            </span>
        </div>
    )
}

export default PersonFilter;